import { all, takeEvery, put } from "redux-saga/effects";
import udemyCoursesService from "../../services/udemyCoursesService";

import { sendToastNotification } from "../../utils/sendToastNotification";
import { udemyCoursesActions } from "../slices/udemyCourses.slice";

export function* getAllUdemyCourses() {
	yield takeEvery(udemyCoursesActions.getAllUdemyCourses.type, function* (action) {
		try {
			yield put(udemyCoursesActions.setUdemyCourseLoading(true));
			const { data } = yield udemyCoursesService.getAllUdemyCourses();
			yield put(udemyCoursesActions.saveUdemyCourse(data));
			yield put(udemyCoursesActions.setUdemyCourseLoading(false));
		} catch (error) {
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(udemyCoursesActions.setUdemyCourseLoading(false));
		}
	});
}

export function* addNewUdemyCourse() {
	yield takeEvery(udemyCoursesActions.addNewUdemyCourse.type, function* (action) {
		try {
			const { udemyCourseDetails, callback } = action.payload;
			yield put(udemyCoursesActions.setUdemyCourseSubmitting(true));
			yield udemyCoursesService.addNewUdemyCourse(udemyCourseDetails);
			yield put(udemyCoursesActions.getAllUdemyCourses());
			yield put(udemyCoursesActions.setUdemyCourseSubmitting(false));

			sendToastNotification({ successMesssage: "Udemy course added successfully" });
			callback();
		} catch (error) {
			console.log(error);
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(udemyCoursesActions.setUdemyCourseSubmitting(false));
		}
	});
}

export function* updateUdemyCourse() {
	yield takeEvery(udemyCoursesActions.updateUdemyCourse.type, function* (action) {
		try {
			const { udemyCourseId, udemyCourseDetails, callback } = action.payload;
			yield put(udemyCoursesActions.setUdemyCourseSubmitting(true));
			yield udemyCoursesService.updateUdemyCourse(udemyCourseId, udemyCourseDetails);
			yield put(udemyCoursesActions.getAllUdemyCourses());
			yield put(udemyCoursesActions.setUdemyCourseSubmitting(false));

			sendToastNotification({ successMesssage: "Udemy course updated successfully" });
			callback();
		} catch (error) {
			console.log(error);
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(udemyCoursesActions.setUdemyCourseSubmitting(false));
		}
	});
}

export function* deleteUdemyCourse() {
	yield takeEvery(udemyCoursesActions.deleteUdemyCourse.type, function* (action) {
		try {
			yield put(udemyCoursesActions.setUdemyCourseSubmitting(true));
			yield udemyCoursesService.deleteUdemyCourse(action.payload);
			yield put(udemyCoursesActions.getAllUdemyCourses());
			yield put(udemyCoursesActions.setUdemyCourseSubmitting(false));

			sendToastNotification({ successMesssage: "Udemy course deleted successfully" });
		} catch (error) {
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(udemyCoursesActions.setUdemyCourseSubmitting(false));
		}
	});
}

export default function* udemyCoursesSaga() {
	yield all([getAllUdemyCourses(), addNewUdemyCourse(), deleteUdemyCourse(), updateUdemyCourse()]);
}
