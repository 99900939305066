import { all } from "redux-saga/effects";
import authSaga from "./auth.saga";
import blogSaga from "./blog.saga";
import couponSaga from "./coupon.saga";
import dashboardPageSaga from "./dashboard.saga";
import instructorSaga from "./instructor.saga";
import internshipProjectSaga from "./internshipProject.saga";
import jobsSaga from "./jobs.saga";
import newsletterSaga from "./newsletter.saga";
import paymentPageSaga from "./payment.saga";
import projectSaga from "./project.sage";
import resourceSaga from "./resource.saga";
import userSaga from "./user.saga";
import workshopPageSaga from "./workshop.saga";
import accessSaga from "./access.saga";
import NewWorkshopPageSaga from "./NewWorkshop.saga";
import couponOldSaga from "./couponOld.saga";
import productRegistrationsSaga from "./productRegistrations.saga";
import newpaymentPageSaga from "./newPayments.saga";
import newTrainingProgramSaga from "./newTrainingProgram.saga";
import udemyCoursesSaga from "./udemyCourses.saga";

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        dashboardPageSaga(),
        projectSaga(),
        workshopPageSaga(),
        paymentPageSaga(),
        userSaga(),
        instructorSaga(),
        resourceSaga(),
        couponSaga(),
        jobsSaga(),
        newsletterSaga(),
        blogSaga(),
        internshipProjectSaga(),
        accessSaga(),
        NewWorkshopPageSaga(),
        couponOldSaga(),
        productRegistrationsSaga(),
        newpaymentPageSaga(),
        newTrainingProgramSaga(),
        udemyCoursesSaga()
    ]);
}
