import { all, put, takeEvery } from "redux-saga/effects";
import newTrainingProgramService from "../../services/newTrainingProgramService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { newTrainingProgramActions } from "../slices/newTrainingProgram.slice";

export function* getNewTrainingPrograms() {
    yield takeEvery(
        newTrainingProgramActions.getAllNewTrainingPrograms.type,
        function* (action) {
            try {
                const { data } =
                    yield newTrainingProgramService.getTrainingPrograms();
                yield put(
                    newTrainingProgramActions.saveNewTrainingPrograms(data)
                );
            } catch (error) {
                console.log(error);
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
            }
        }
    );
}

export function* addNewNewTrainingProgram() {
    yield takeEvery(
        newTrainingProgramActions.addNewNewTrainingProgram.type,
        function* (action) {
            try {
                // yield put(newTrainingProgramActions.setFormSubmiting(true));
                yield newTrainingProgramService.postTrainingPrograms(
                    action.payload
                );
                yield put(
                    newTrainingProgramActions.getAllNewTrainingPrograms()
                );
                //yield put(newTrainingProgramActions.setFormSubmiting(false));
                sendToastNotification({
                    successMesssage:
                        "New Training Program created successfully",
                });
            } catch (error) {
                console.log(error);
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                    yield put(
                        newTrainingProgramActions.setError(
                            error.response.data.message
                        )
                    );
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(newTrainingProgramActions.setFormSubmiting(false));
            }
        }
    );
}

export function* updateNewTrainingProgram() {
    yield takeEvery(
        newTrainingProgramActions.updateNewTrainingProgram.type,
        function* (action) {
            try {
                console.log("action", action.payload);
                const { id, data } = action.payload;
                yield newTrainingProgramService.updateTrainingProgram(id, data);
                yield put(
                    newTrainingProgramActions.getAllNewTrainingPrograms()
                );
                // yield put(newTrainingProgramActions.setFormSubmiting(false));
                sendToastNotification({
                    successMesssage:
                        "New Training Program Updated Successfully",
                });
            } catch (error) {
                console.log(error);
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(newTrainingProgramActions.setFormSubmiting(false));
            }
        }
    );
}

export function* getPaymentDetailsOfNewTrainingProgram() {
    yield takeEvery(
        newTrainingProgramActions.getPaymentDetailsOfNewTrainingProgram.type,
        function* (action) {
            try {
                const { data } =
                    yield newTrainingProgramService.getPaymentDetailsOfNewTrainingProgram(
                        action.payload
                    );
                console.log("data", data);
                yield put(newTrainingProgramActions.savePaymentDetails(data));
                // yield put(
                //     newTrainingProgramActions.savePaymentDetailsOfNewTrainingProgram(
                //         data
                //     )
                // );
            } catch (error) {
                console.log(error);
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
            }
        }
    );
}

export default function* newTrainingProgramSaga() {
    yield all([
        getNewTrainingPrograms(),
        addNewNewTrainingProgram(),
        updateNewTrainingProgram(),
        getPaymentDetailsOfNewTrainingProgram(),
        // deleteNewTrainingProgram(),
        // getPaymentDetailsOfNewTrainingProgram(),
    ]);
}
