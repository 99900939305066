import { ironManInstance } from "./apiService";

const getAllJobs = ({ size, pageNo }) => {
    return ironManInstance.get(`/jobs?${size ? `size=${size}` : ""}${pageNo ? `&pageNo=${pageNo}` : ""}`);
};

const getJobById = (id) => {
    return ironManInstance.get(`/jobs/${id}`);
};
const updateJob = (id, jobDetails) => {
    return ironManInstance.put(`/jobs/${id}`, jobDetails);
};

const createJob = (jobDetails) => {
    return ironManInstance.post("/jobs", jobDetails);
};

const deleteJob = (id) => {
    return ironManInstance.delete(`/jobs/${id}`);
};

export default {
    getAllJobs,
    getJobById,
    createJob,
    deleteJob,
    updateJob,
};
