import { createSlice } from "@reduxjs/toolkit";
import { decodeCurrentUser, deleteAuthToken, getAuthToken, setAuthToken } from "../../services/storageService";

const initialState = {
	token: "",
	email: "",
	otpSent: false,
	currentUser: null,
	loading: false,
	error: null
};
const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		sendOtpRequest: (state) => {
			state.loading = true;
			state.error = null;
		},
		sendOtpSuccess: (state) => {
			state.otpSent = true;
			state.loading = false;
		},
		sendOtpFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		verifyOtpRequest: (state) => {
			state.loading = true;
			state.error = null; 
		},
		verifyOtpFailure: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		  },
		loginUser(state, action) {
			state.loading = true;
			const token = action.payload;
			setAuthToken(token);
			const decodedUserData = decodeCurrentUser(token);
			state.token = token;
			state.currentUser = decodedUserData;
			state.loading = false;
			state.otpSent = false;
			state.error = null;
		},
		logOutUser(state) {
			deleteAuthToken();
			state.token = null;
			state.currentUser = null;
			state.loading = false;
		},
		loadUserFromLocalStorageAndLogin(state) {
			state.loading = true;
			const token = getAuthToken();
			if (token) {
				setAuthToken(token);
				const decodedUserData = decodeCurrentUser(token);
				state.token = token;
				state.currentUser = decodedUserData;
				state.loading = false;
			}
			state.loading = false;
		},
	},
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
