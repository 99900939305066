import httpService from "./apiService";

const getAllActiveWorkshops = () => httpService.get("/workshop");
const addNewWorkshop = (data) => httpService.post("/workshop", { ...data });
const updateWorkshop = ({ id, workshopData }) => httpService.put(`/workshop/${id}`, workshopData);
const getWorkshopPaymentsByProjectId = (id) => httpService.get(`/v1/workshops/${id}/payments`);

export default {
	getAllActiveWorkshops,
	addNewWorkshop,
	updateWorkshop,
	getWorkshopPaymentsByProjectId,
};
