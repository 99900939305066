import { all, takeEvery, put } from "redux-saga/effects";
import internshipProjectService from "../../services/internshipProjectService";

import { sendToastNotification } from "../../utils/sendToastNotification";
import { internshipProjectActions } from "../slices/internshipProject";

export function* getAllInternshipProjects() {
    yield takeEvery(internshipProjectActions.getAllInternshipProjects.type, function* (action) {
        try {
            const { pageNo, size } = action.payload;
            yield put(internshipProjectActions.setInternshipProjectLoading(true));
            const { data } = yield internshipProjectService.getAllProjects({
                pageNo: pageNo || 1,
                size: size || 10,
            });
            yield put(internshipProjectActions.saveInternshipProjects(data));
            yield put(internshipProjectActions.setInternshipProjectLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(internshipProjectActions.setInternshipProjectLoading(false));
        }
    });
}
export function* AddNewInternshipProject() {
    yield takeEvery(internshipProjectActions.addInternshipProject.type, function* (action) {
        try {
            yield put(internshipProjectActions.setCreateInternshipProjectLoading(true));
            yield internshipProjectService.addProject(action.payload);
            yield put(
                internshipProjectActions.getAllInternshipProjects({
                    pageNo: 1,
                    size: 20,
                })
            );
            yield put(internshipProjectActions.setCreateInternshipProjectLoading(false));

            sendToastNotification({ successMesssage: "Project Added Successfully" });
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(internshipProjectActions.setCreateInternshipProjectLoading(false));
        }
    });
}
export function* updateInternshipProject() {
    yield takeEvery(internshipProjectActions.updateInternshipProject.type, function* (action) {
        try {
            const { id, data } = action.payload;
            yield put(internshipProjectActions.setUpdateInternshipProjectLoading(true));
            yield internshipProjectService.updateProject(id, data);
            yield put(
                internshipProjectActions.getAllInternshipProjects({
                    pageNo: 1,
                    size: 20,
                })
            );
            yield put(internshipProjectActions.setUpdateInternshipProjectLoading(false));

            sendToastNotification({ successMesssage: "Project Updated Successfully" });
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(internshipProjectActions.setUpdateInternshipProjectLoading(false));
        }
    });
}
export function* publishInternshipProject() {
    yield takeEvery(internshipProjectActions.publishInternshipProject.type, function* (action) {
        try {
            yield put(internshipProjectActions.setUpdateInternshipProjectLoading(true));
            yield internshipProjectService.publishProject(action.payload);
            yield put(
                internshipProjectActions.getAllInternshipProjects({
                    pageNo: 1,
                    size: 20,
                })
            );
            yield put(internshipProjectActions.setUpdateInternshipProjectLoading(false));

            sendToastNotification({ successMesssage: "Status updated successfully" });
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(internshipProjectActions.setUpdateInternshipProjectLoading(false));
        }
    });
}
export function* deleteInternshipProject() {
    yield takeEvery(internshipProjectActions.deleteInternshipProject.type, function* (action) {
        try {
            yield put(internshipProjectActions.setDeleteInternshipProjectLoading(true));
            yield internshipProjectService.deleteProject(action.payload);
            yield put(
                internshipProjectActions.getAllInternshipProjects({
                    pageNo: 1,
                    size: 20,
                })
            );
            yield put(internshipProjectActions.setDeleteInternshipProjectLoading(false));

            sendToastNotification({ successMesssage: "Project deleted Successfully" });
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(internshipProjectActions.setDeleteInternshipProjectLoading(false));
        }
    });
}

export default function* internshipProjectSaga() {
    yield all([
        getAllInternshipProjects(),
        AddNewInternshipProject(),
        updateInternshipProject(),
        publishInternshipProject(),
        deleteInternshipProject(),
    ]);
}
