import { message } from "antd";
import React from "react";
import userService from "../../services/userService";
import DebounceSelect from "./DebounceSelect";

export default function FechUserInput({ onChange, value, mode }) {
    async function fetchUsers(email) {
        try {
            const { data } = await userService.getNewUsers({
                search: email,
                search_by: "email",
            });
            return data.users.map((user) => ({
                label: `${user.name}`,
                value: user._id,
            }));
        } catch (error) {
            message.error(error.message);
            return [];
        }
    }
    return (
        <DebounceSelect
            mode={mode}
            value={value}
            placeholder="Select users"
            fetchOptions={fetchUsers}
            onChange={onChange}
            style={{
                width: "100%",
            }}
        />
    );
}
