import { ironManInstance } from "./apiService";

const getNewUsers = (reqBody) => {
    const requestBody = {};

    requestBody.pageNo = reqBody.pageNo ? reqBody.pageNo : null;
    requestBody.size = reqBody.size ? reqBody.size : null;
    requestBody.search_value = reqBody.search ? reqBody.search : null;
    requestBody.search_by = reqBody.search_by ? reqBody.search_by : null;

    console.log("requestbody", requestBody);
    return ironManInstance.post('/users/all-users', requestBody);
};

const getCoursesUnregisteredUsers = ({ pageNo, size }) => {
    return ironManInstance.post(
        `/users/all-unenrolled-users?size=${size}&pageNo=${pageNo}`
    );
};

const getUsersData = () => {
    return ironManInstance.get(`/users/me`);
};

const userService = {
    getNewUsers,
    getCoursesUnregisteredUsers,
    getUsersData,
};

export default userService;