import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./Pages/Home/Home";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { useDispatch } from "react-redux";
import { authActions } from "./redux/slices/auth.slice";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import AccessViewer from "./Pages/AccessViewer/AccessViewer";
// import { LinkedInCallback } from "react-linkedin-login-oauth2";
// import InternshipProjects from "./Pages/InternshipProjects/InternshipProjects";
// import AddNewInternshipProject from "./Pages/InternshipProjects/AddNewInternshipProject";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authActions.loadUserFromLocalStorageAndLogin());
  }, []);
  return (
    <div className="App">
      <Switch>
        <Route path="/login" component={Login} />
        <ProtectedRoute path="/" component={Home} />
        <Redirect to="/login" from="/" />
      </Switch>
    </div>
  );
};

export default App;
