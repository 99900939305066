import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	coupons: [],
	loading: {
		coupons: false,
		createCoupon: false,
	},
};

const couponSlice = createSlice({
	name: "coupon",
	initialState,
	reducers: {
		addNewCoupon() {},
		editCoupon() {},
		deleteCoupon() {},
		getAllCoupons() {},
		updateCoupon() {},
		saveCoupons(state, action) {
			state.coupons = action.payload;
		},
		setCouponLoading(state, action) {
			state.loading.coupons = action.payload;
		},
		setCouponSubmiting(state, action) {
			state.loading.createCoupon = action.payload;
		},
	},
});

export const couponReducer = couponSlice.reducer;
export const couponActions = couponSlice.actions;
