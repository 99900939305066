import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	users: [],
	usersCount: 0,
	loading: {
		users: false,
		courseUnregisteredUsers: false,
	},
	courseUnregisteredUsers: [],
	courseUnregisteredUsersCount: 0,
};
const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		getAllUsers() {},
		getAllCourseUnregisteredUsers() {},
		saveAllUsers(state, action) {
			state.users = action.payload.users;
			state.usersCount = action.payload.count;
		},
		saveCourseUnregisteredUsers(state, action) {
			state.courseUnregisteredUsers = action.payload.users;
			state.courseUnregisteredUsersCount = action.payload.count;
		},
		setuserLoading(state, action) {
			state.loading.users = action.payload;
		},
		setCourseUnregisteredUsersLoading(state, action) {
			state.loading.courseUnregisteredUsers = action.payload;
		},
	},
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
