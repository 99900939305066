import { all, takeEvery, put } from "redux-saga/effects";
import resourceService from "../../services/resourceService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { resourceActions } from "../slices/resources.slice";

export function* getAllResources() {
	yield takeEvery(resourceActions.getAllResources.type, function* (action) {
		try {
			yield put(resourceActions.setResourcesLoading(true));
			const { data } = yield resourceService.getAllResources();
			yield put(resourceActions.saveAllResources(data));
			yield put(resourceActions.setResourcesLoading(false));
		} catch (error) {
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(resourceActions.setResourcesLoading(false));
		}
	});
}
export default function* resourceSaga() {
	yield all([getAllResources()]);
}
