import axios from "axios";
import jwt from "jwt-decode";
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL;
// axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("_authToken");
        if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       return authService.getRefreshedToken().then((res) => {
//         if (res.status === 200) {
//           localStorage.setItem("_accessToken", res.data.accessToken);
//           console.log("Access token refreshed!");
//           return axios(originalRequest);
//         }
//       });
//     }
//     localStorage.removeItem("_accessToken");
//     return Promise.reject(error);
//   }
// );

// // axios.interceptors.response.use(null, (error) => {
// //   const expectedError =
// //     error.response &&
// //     error.response.status >= 400 &&
// //     error.response.status <= 500;
// //   if (!expectedError) {
// //     // console.log("Logging the error", error);
// //     console.log("Unexpected Error occured");
// //   }
// //   return Promise.reject(error);
// // });
// // const setJwt = (token) => {
// //   axios.defaults.headers.common["x-auth-token"] = `Bearer ${token}`;
// // };
const axiosIronMannInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_IRONMAN
});

axiosIronMannInstance.interceptors.request.use(
    (config) => {
        if (typeof window !== "undefined") {
            const accessToken = localStorage.getItem("_authToken");
            if (accessToken) {
                const tokenData = jwt(accessToken);
                if (Date.now() < tokenData.exp * 1000) {
                    config.headers["Authorization"] = `Bearer ${accessToken}`;
                } else {
                    // logout
                    localStorage.removeItem("_authToken");
                    window.location.replace("/");
                }
            }
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export const ironManInstance = {
    get: axiosIronMannInstance.get,
    post: axiosIronMannInstance.post,
    put: axiosIronMannInstance.put,
    delete: axiosIronMannInstance.delete,
    patch: axiosIronMannInstance.patch,
};
const httpService = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
};

export default httpService;
