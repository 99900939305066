import { all, takeEvery, put } from "redux-saga/effects";
import userService from "../../services/userService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { userActions } from "../slices/user.slice";
export function* getAllUsers() {
    yield takeEvery(userActions.getAllUsers.type, function* (action) {
        try {
            const { pageNo, size, search, search_by } = action.payload;
            yield put(userActions.setuserLoading(true));
            const { data } = yield userService.getNewUsers({
                pageNo,
                size,
                search,
                search_by,
            });
            yield put(userActions.saveAllUsers(data));
            yield put(userActions.setuserLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(userActions.setuserLoading(false));
        }
    });
}
export function* getAllCourseUnregisteredUsers() {
    yield takeEvery(userActions.getAllCourseUnregisteredUsers.type, function* (action) {
        try {
            const { pageNo, size } = action.payload;
            yield put(userActions.setCourseUnregisteredUsersLoading(true));
            const { data } = yield userService.getCoursesUnregisteredUsers({
                pageNo,
                size,
            });
            yield put(userActions.saveCourseUnregisteredUsers(data));
            yield put(userActions.setCourseUnregisteredUsersLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(userActions.setCourseUnregisteredUsersLoading(false));
        }
    });
}

export default function* userSaga() {
    yield all([getAllUsers(), getAllCourseUnregisteredUsers()]);
}
