import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    projectsLoading: false,
    projects: [],
    submiting: false,
    error: "",
    loading: {
        payments: false,
        users: false,
        checkouts: false,
    },
    projectPayment: {
        payments: [],
        paymentCount: 0,
        total: 0,
    },
    enrolledUsers: {
        count: 0,
        users: [],
    },
    projectCheckouts: {
        checkouts: [],
        checkoutsCount: 0,
    },
};

const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        getAllProjects() {},
        updateProject() {},
        addNewProject() {},
        getPaymentDetailsOfProject() {},
        getEnrollUsersOfAProject() {},
        getProjectCheckoutsData() {},
        updateBasicTimingDetails() {},
        saveProjects(state, action) {
            state.projects = action.payload;
        },
        setProjectsLoading(state, action) {
            state.projectsLoading = action.payload;
        },
        setFormSubmiting(state, action) {
            state.submiting = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        paymentLoading(state, action) {
            state.loading.payments = action.payload;
        },
        enrolledUsersLoading(state, action) {
            state.loading.users = action.payload;
        },
        setProjectPayment(state, action) {
            const { payments, total } = action.payload;
            state.projectPayment.payments = payments;
            state.projectPayment.paymentCount = payments.length;
            state.projectPayment.total = total;
        },
        setEnrolledUsers(state, action) {
            const users = action.payload;
            state.enrolledUsers.users = users;
            state.enrolledUsers.count = users.length;
        },
        setProjectCheckouts(state, action) {
            state.projectCheckouts.checkouts = action.payload.checkouts;
            state.projectCheckouts.checkoutsCount = action.payload.count;
        },
        setCheckoutLoading(state, action) {
            state.loading.checkouts = action.payload;
        },
        updateEnrolledUsersCount(state, action) {
            state.enrolledUsers.count = action.payload;
        },
    },
});
export const projectReducer = projectSlice.reducer;
export const projectActions = projectSlice.actions;
