import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    blogCount: 0,
    blogs: [],
    loading: {
        blogs: false,
        update: false,
        myBlogs: false,
    },
    unAprovedBlogs: [],
    myBlogs: {
        blogCount: 0,
        unAprovedBlogs: [],
        publishedBlogs: [],
        draftedBlogs: [],
    },
};

const blogSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {
        addBlog: (state, action) => {},
        getMyBlogs: (state, action) => {},
        updateBlog: (state, action) => {},
        getUnAprovedBlogs: (state, action) => {},
        approveBlog: (state, action) => {},
        deleteBlog: (state, action) => {},
        getBlogs: (state, action) => {},
        saveBlogs: (state, action) => {
            state.blogs = action.payload.blogs;
            state.blogCount = action.payload.count;
        },
        setUpdating: (state, action) => {
            state.loading.update = action.payload;
        },
        setMyBlogLoading: (state, action) => {
            state.loading.myBlogs = action.payload;
        },
        setBlogsLoading: (state, action) => {
            state.loading.blogs = action.payload;
        },
        saveUnapprovedBlogs: (state, action) => {
            state.unAprovedBlogs = action.payload;
        },
        saveMyBlogs(state, action) {
            console.log("blogs", action.payload);
            const myBlogs = action.payload || [];
            state.myBlogs.blogCount = myBlogs.length;
            state.myBlogs.unAprovedBlogs = myBlogs.filter(
                (blog) => blog.isPublished === true && blog.isAproved === false
            );
            state.myBlogs.publishedBlogs = myBlogs.filter(
                (blog) => blog.isPublished === true && blog.isAproved === true
            );
            state.myBlogs.draftedBlogs = myBlogs.filter(
                (blog) => blog.isPublished === false && blog.isAproved === false
            );
        },
    },
});

export const blogReducer = blogSlice.reducer;
export const blogActions = blogSlice.actions;
