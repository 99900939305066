import { ironManInstance } from "./apiService";

const getAccessViewerList = ({ size, pageNo }) => {
    const data = ironManInstance.get(`/access`);
    return data;
};

const getAccessList = () => {
    const data = ironManInstance.get("/access/available-access");
    // console.log("data", data);
    return data;
};
const updateAccess = (id, accessDetails) => {
    return ironManInstance.patch(`/access/${id}`, accessDetails);
};
const addAccess = (accessDetails) => {
    return ironManInstance.post(`/access`, accessDetails);
};
const deleteAccess = (id) => {
    return ironManInstance.delete(`/access/${id}`);
};
const getMyAccess = () => {
    // console.log("112");
    return ironManInstance.get(`/access/my-access`);
};
export default {
    getAccessViewerList,
    getAccessList,
    updateAccess,
    addAccess,
    deleteAccess,
    getMyAccess,
};
