import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	dashboardPageLoading: false,
	dashboardPageData: null,
};
const dashboardPageSlice = createSlice({
	name: "home",
	initialState,
	reducers: {
		getDashboardpageData() {},
		saveDashboardPageData(state, action) {
			state.dashboardPageData = action.payload;
		},
		setDashoardLoading(state, action) {
			state.dashboardPageLoading = action.payload;
		},
	},
});
export const dashboardPageReducer = dashboardPageSlice.reducer;
export const dashboardPageActions = dashboardPageSlice.actions;
