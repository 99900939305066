import { all, takeEvery, put } from "redux-saga/effects";
import dashboardPageService from "../../services/dashboardPageService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { dashboardPageActions } from "../slices/dashboard.slice";
export function* getDashboardPageData() {
	yield takeEvery(dashboardPageActions.getDashboardpageData.type, function* (action) {
		try {
			yield put(dashboardPageActions.setDashoardLoading(true));
			const { data } = yield dashboardPageService.getAllDashboardHomeData();
			yield put(dashboardPageActions.saveDashboardPageData(data));
			yield put(dashboardPageActions.setDashoardLoading(false));
		} catch (error) {
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
		}
	});
}

export default function* dashboardPageSaga() {
	yield all([getDashboardPageData()]);
}
