import React from "react";
import { Redirect, Route, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { accessActions } from "../../redux/slices/accessviewer.slice";
import { useCallback } from "react";

import { message } from "antd";
const routeMapping = {
    dashboard: "dashboard",
    "internship-projects": "internship-projects",
    project: "training-programs",
    workshop: "workshops",
    events: "events",
    blogs: "blogs",
    users: "users",
    roles: "roles",
    payments: "payments",
    instructors: "instructors",
    resources: "resources",
    courses: "courses",
    coupons: "coupons",
    jobs: "jobs",
    newsletter: "news-letters",
    "my-access": "my-access",
};
function ProtectedRoute({ path, component: Component, render, ...rest }) {
    const location = useLocation();
    const { token, currentUser } = useSelector((state) => state.auth);
    const { myAccess } = useSelector((state) => state.access);
    const dispatch = useDispatch();
    const history = useHistory();
    function checkMappingMatch() {
        const currentRouteName = location.pathname.slice(1);
        const key = routeMapping[currentRouteName];
        if (!key) {
            return false;
        }
        return myAccess?.accesses?.find((curr) => {
            return curr.access === key;
        });
    }

    const validateAccessAndRedirect = useCallback(() => {
        if (myAccess?.role?.includes("superAdmin")) {
        } else {
            if (
                location.pathname === "/my-access" ||
                location.pathname === "/"
            ) {
            } else {
                if (checkMappingMatch()) {
                } else {
                    message.config({
                        maxCount: 1,
                    });
                    message.error("You dont Have Access for this This Page!");
                    history.replace("/my-access");
                }
            }
        }
    }, []);
    useEffect(() => {
        if (Object.keys(myAccess).length === 0) {
            dispatch(accessActions.getMyAccess());
        } else {
            // console.log(myAccess);
            if (myAccess?.role[0] === "superAdmin") {
            } else {
                validateAccessAndRedirect();
            }
        }
    }, [myAccess]);
    return (
        <Route
            {...rest}
            path={path}
            render={(props) => {
                if (!currentUser && !token) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    );
                }
                return Component ? <Component {...props} /> : render(props);
            }}
        />
    );
}

export default ProtectedRoute;
