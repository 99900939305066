import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    internshipProjects: [],
    count: 0,
    loading: {
        internshipProjects: false,
        createInternshipProject: false,
        deleteInternshipProject: false,
        updateInternshipProject: false,
    },
};

const internshipProjectsSlice = createSlice({
    name: "internshipProjects",
    initialState,
    reducers: {
        getAllInternshipProjects(state, action) {},
        addInternshipProject: (state, action) => {},
        updateInternshipProject: (state, action) => {},
        deleteInternshipProject: (state, action) => {},
        publishInternshipProject: (state, action) => {},
        saveInternshipProjects(state, action) {
            const { projects, count } = action.payload;
            state.internshipProjects = projects;
            state.count = count;
        },
        setInternshipProjectLoading(state, action) {
            state.loading.internshipProjects = action.payload;
        },
        setCreateInternshipProjectLoading(state, action) {
            state.loading.createInternshipProject = action.payload;
        },
        setDeleteInternshipProjectLoading(state, action) {
            state.loading.deleteInternshipProject = action.payload;
        },
        setUpdateInternshipProjectLoading(state, action) {
            state.loading.updateInternshipProject = action.payload;
        },
    },
});

export const internshipProjectReducer = internshipProjectsSlice.reducer;
export const internshipProjectActions = internshipProjectsSlice.actions;
