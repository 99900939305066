import { all, takeEvery, put } from "redux-saga/effects";
import instructorService from "../../services/instructorService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { instructorActions } from "../slices/instructor.slice";

export function* getAllInstructor() {
    yield takeEvery(
        instructorActions.getAllInstructors.type,
        function* (action) {
            try {
                yield put(instructorActions.instructorLoading(true));
                const { data } = yield instructorService.getAllInstructors();
                yield put(instructorActions.saveInstructor(data));
                yield put(instructorActions.instructorLoading(false));
            } catch (error) {
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(instructorActions.instructorLoading(false));
            }
        }
    );
}

export function* addNewInstructor() {
    yield takeEvery(
        instructorActions.addnewInstructor.type,
        function* (action) {
            try {
                const instructorDetails = action.payload;
                yield put(instructorActions.instructorSubmiting(true));
                const { data } = yield instructorService.addNewInstructor(
                    instructorDetails
                );
                if (data) {
                    yield put(instructorActions.getAllInstructors());
                }
                yield put(instructorActions.instructorSubmiting(false));
            } catch (error) {
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(instructorActions.instructorSubmiting(false));
            }
        }
    );
}
export function* updateInstructor() {
    yield takeEvery(
        instructorActions.updateInstructor.type,
        function* (action) {
            try {
                const { id, instructorDetails } = action.payload;
                console.log({ id, instructorDetails });
                yield put(instructorActions.instructorSubmiting(true));
                const { data } = yield instructorService.updateInstructor(id, {
                    ...instructorDetails,
                });

                yield put(instructorActions.getAllInstructors());
                yield put(instructorActions.instructorSubmiting(false));
                sendToastNotification({
                    successMesssage: "Instructor Updated!",
                });
            } catch (error) {
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(instructorActions.instructorSubmiting(false));
            }
        }
    );
}
export function* deleteInstructor() {
    yield takeEvery(
        instructorActions.deleteInstructor.type,
        function* (action) {
            try {
                const id = action.payload;
                yield put(instructorActions.instructorSubmiting(true));
                yield instructorService.deleteInstructor(id);
                yield put(instructorActions.getAllInstructors());
                yield put(instructorActions.instructorSubmiting(false));
            } catch (error) {
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(instructorActions.instructorSubmiting(false));
            }
        }
    );
}

export default function* instructorSaga() {
    yield all([
        getAllInstructor(),
        addNewInstructor(),
        updateInstructor(),
        deleteInstructor(),
    ]);
}
