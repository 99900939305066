import { all, put, takeEvery } from "redux-saga/effects";
import NewWorkshopService from "../../services/NewWorkshopService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { NewWorkShopActions } from "../slices/NewWorkshop.slice";

export function* getWorkshops() {
    yield takeEvery(NewWorkShopActions.getAllWorkshop.type, function* (action) {
        try {
            yield put(NewWorkShopActions.setWorkshopLoading(true));
            const { data } = yield NewWorkshopService.getAllActiveWorkshops();
            console.log();
            yield put(NewWorkShopActions.saveWorkshop(data));
            yield put(NewWorkShopActions.setWorkshopLoading(false));
        } catch (error) {
            console.log(error);
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(NewWorkShopActions.setWorkshopLoading(false));
        }
    });
}
export function* addNewWorkshop() {
    yield takeEvery(NewWorkShopActions.addNewWorkshop.type, function* (action) {
        try {
            yield put(NewWorkShopActions.setFormSubmiting(true));
            yield NewWorkshopService.addNewWorkshop(action.payload);
            yield put(NewWorkShopActions.getAllWorkshop());
            yield put(NewWorkShopActions.setFormSubmiting(false));
        } catch (error) {
            console.log(error);
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
                yield put(
                    NewWorkShopActions.setError(error.response.data.message)
                );
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(NewWorkShopActions.setFormSubmiting(false));
        }
    });
}
export function* updateWorkshop() {
    yield takeEvery(NewWorkShopActions.updateWorkshop.type, function* (action) {
        try {
            const { id, workshopData } = action.payload;
            // console.log("act", action.payload);
            yield put(NewWorkShopActions.setFormSubmiting(true));
            // console.log("act-load", id, workshopData);
            yield NewWorkshopService.updateWorkshop({
                id,
                workshopData: workshopData,
            });
            yield put(NewWorkShopActions.getAllWorkshop());
            yield put(NewWorkShopActions.setFormSubmiting(false));
            sendToastNotification({
                successMesssage: "Workshop Updated Successfully",
            });
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
                yield put(
                    NewWorkShopActions.setError(error.response.data.message)
                );
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(NewWorkShopActions.setFormSubmiting(false));
        }
    });
}

export function* getAllPaymentsOfAWorkshop() {
    yield takeEvery(
        NewWorkShopActions.getProjectPayments.type,
        function* (action) {
            try {
                const id = action.payload;
                yield put(NewWorkShopActions.setPaymentLoading(true));
                const { data } =
                    yield NewWorkshopService.getWorkshopPaymentsByProjectId(id);
                yield put(NewWorkShopActions.setWorkshopPayments(data));
                yield put(NewWorkShopActions.setPaymentLoading(false));
            } catch (error) {
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(NewWorkShopActions.setPaymentLoading(false));
            }
        }
    );
}

export default function* NewWorkshopPageSaga() {
    yield all([
        getWorkshops(),
        addNewWorkshop(),
        updateWorkshop(),
        getAllPaymentsOfAWorkshop(),
    ]);
}
