import { Button, Card, Table, Tag } from "antd";
import { useSelector } from "react-redux";
import { ExportOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx/xlsx.mjs";
const columns = [
    {
        title: "S.No",
        dataIndex: "idx",
        key: "idx",
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
    },
    {
        title: "Billing Address",
        dataIndex: "BillingAddress",
        key: "BillingAddress",
        render: (text, record) => {
            return (
                <>
                    <p>
                        <b>Address 1:</b> {record?.billingAddress?.address1}
                    </p>
                    <p>
                        <b>Address 2: </b>
                        {record?.billingAddress?.address2
                            ? record?.billingAddress?.address2
                            : " Not Provided"}
                    </p>
                    <p>
                        <b>City: </b>
                        {record?.billingAddress?.city}
                    </p>
                    <p>
                        <b>State: </b>
                        {record?.billingAddress?.state}
                    </p>
                    <p>
                        <b>Country: </b>
                        {record?.billingAddress?.country}
                    </p>
                    <p>
                        <b>zipcode: </b>
                        <Tag
                            color={
                                record?.billingAddress?.zipcode?.length === 6
                                    ? "green"
                                    : "red"
                            }
                        >
                            {record?.billingAddress?.zipcode}
                        </Tag>
                    </p>
                </>
            );
        },
    },
];

function RegisteredUsers({ users, id }) {
    console.log(users);
    const convertDataToTableDataFormat = (rows = []) => {
        return rows.map((row, index) => {
            return {
                idx: index + 1,
                ...row,
            };
        });
    };
    const convertDataToExpartFormat = (rows = []) => {
        return rows.map((row, index) => {
            return {
                idx: index + 1,
                ...row,
            };
        });
    };
    const exportToExcel = () => {
        /* convert state to workbook */
        const ws = XLSX.utils.json_to_sheet(convertDataToExpartFormat(users));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, `RegisteredUsers${id}.xlsx`);
    };
    const { myAccess } = useSelector((state) => state.access);
    return (
        <div>
            <Card
                title={
                    <div className="flex justify-end">
                        {myAccess.role.includes("superAdmin") && (
                            <Button
                                icon={<ExportOutlined />}
                                className="flex items-center justify-center"
                                onClick={exportToExcel}
                            >
                                Export
                            </Button>
                        )}
                    </div>
                }
                className="rounded-md p-0 home-latest-txn"
            >
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={convertDataToTableDataFormat(users)}
                />
            </Card>
        </div>
    );
}

export default RegisteredUsers;
