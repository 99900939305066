import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Compress from "browser-image-compression";

import { uploadFile } from "../../utils/uploadFile";

export default function TinymceEditor({ value, onChange, initialValue = "" }) {
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };
    return (
        <>
            <Editor
                apiKey="mfff2pkioryo39svn5a4gaelx68p4bvo68crz8qje5durbvp"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={initialValue}
                value={value}
                init={{
                    height: 500,
                    // menubar: false,
                    // plugins: [
                    //     "lists advlist autolink link image charmap print preview anchor",
                    //     "searchreplace visualblocks code fullscreen",
                    //     "insertdatetime media table paste code help wordcount",
                    // ],
                    // toolbar:
                    //     "undo redo | formatselect | " +
                    //     "bold italic backcolor | alignleft aligncenter" +
                    //     "alignright alignjustify | image numlist bullist | outdent indent | " +
                    //     "removeformat | help",
                    toolbar_mode: "sliding",
                    menubar: "file edit view insert format tools table tc help",
                    plugins:
                        "advlist lists link autolink paste help wordcount table image fullscreen autoresize media lineheight quickbars code preview textcolor hr anchor emoticons nonbreaking pagebreak charmap codesample",
                    toolbar:
                        "undo redo formatselect blocks bold italic forecolor backcolor emoticons | alignleft aligncenter alignright alignjustify image link lineheight hr nonbreaking pagebreak| bullist numlist outdent indent media charmap | removeformat help | codesample table tabledelete fullscreen wordcount preview",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:20px }",
                    images_upload_credentials: true,
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: "image",
                    file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");
                        input.onchange = async function () {
                            var file = this.files[0];
                            console.log(file);
                            try {
                                const compressedFile = await Compress(file, {
                                    maxSizeMB: 0.3,
                                    useWebWorker: true,
                                });
                                const newfile = new File(
                                    [compressedFile],
                                    file.name,
                                    {
                                        type: file.type,
                                        lastModified: Date.now(),
                                    }
                                );
                                uploadFile(newfile, "new-blogs", () => {})
                                    .then((res) => {
                                        console.log(res);
                                        cb(res.url, { title: file.name });
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            } catch (error) {
                                console.log(error);
                            }
                        };
                        input.click();
                    },
                    // images_upload_handler: async (blobInfo, success, failure) => {
                    //     // try {
                    //     //     const compressedFile = await Compress(file, {
                    //     //         maxSizeMB: 0.3,
                    //     //         useWebWorker: true,
                    //     //     });
                    //     //     const newfile = new File([compressedFile], file.name, {
                    //     //         type: file.type,
                    //     //         lastModified: Date.now(),
                    //     //     });
                    //     //     const { url } = await uploadFile(newfile, "projects", onProgress);
                    //     // } catch (error) {
                    //     // }
                    // },
                }}
                onEditorChange={(content, editor) => {
                    // console.log("Content was changed:", content);
                    onChange(content);
                }}
            />
        </>
    );
}
