import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    coupons: [],
    loading: {
        coupons: false,
        createCoupon: false,
    },
};

const couponOldSlice = createSlice({
    name: "couponOld",
    initialState,
    reducers: {
        addNewCoupon() {},
        editCoupon() {},
        deleteCoupon() {},
        getAllCoupons() {},
        updateCoupon() {},
        saveCoupons(state, action) {
            state.coupons = action.payload;
        },
        setCouponLoading(state, action) {
            state.loading.coupons = action.payload;
        },
        setCouponSubmiting(state, action) {
            state.loading.createCoupon = action.payload;
        },
    },
});

export const couponOldReducer = couponOldSlice.reducer;
export const couponOldActions = couponOldSlice.actions;
