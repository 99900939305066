import { all, takeEvery, put } from "redux-saga/effects";
import couponService from "../../services/couponService";

import { sendToastNotification } from "../../utils/sendToastNotification";
import { couponActions } from "../slices/coupon.slice";

export function* getAllCoupons() {
	yield takeEvery(couponActions.getAllCoupons.type, function* (action) {
		try {
			yield put(couponActions.setCouponLoading(true));
			const { data } = yield couponService.getAllTokens();
			yield put(couponActions.saveCoupons(data));
			yield put(couponActions.setCouponLoading(false));
		} catch (error) {
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(couponActions.setCouponLoading(false));
		}
	});
}

export function* addNewCoupon() {
	yield takeEvery(couponActions.addNewCoupon.type, function* (action) {
		try {
			const { couponDetails, callback } = action.payload;
			yield put(couponActions.setCouponSubmiting(true));
			yield couponService.addnewtoken(couponDetails);
			yield put(couponActions.getAllCoupons());
			yield put(couponActions.setCouponSubmiting(false));

			sendToastNotification({ successMesssage: "Coupon generated successfully" });
			callback();
		} catch (error) {
			console.log(error);
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(couponActions.setCouponSubmiting(false));
		}
	});
}
export function* updateCoupon() {
	yield takeEvery(couponActions.updateCoupon.type, function* (action) {
		try {
			const { couponId, couponDetails, callback } = action.payload;
			yield put(couponActions.setCouponSubmiting(true));
			yield couponService.updateCoupon(couponId, couponDetails);
			yield put(couponActions.getAllCoupons());
			yield put(couponActions.setCouponSubmiting(false));

			sendToastNotification({ successMesssage: "Coupon updated successfully" });
			callback();
		} catch (error) {
			console.log(error);
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(couponActions.setCouponSubmiting(false));
		}
	});
}
export function* deleteCoupon() {
	yield takeEvery(couponActions.deleteCoupon.type, function* (action) {
		try {
			yield put(couponActions.setCouponSubmiting(true));
			yield couponService.deleteCoupon(action.payload);
			yield put(couponActions.getAllCoupons());
			yield put(couponActions.setCouponSubmiting(false));

			sendToastNotification({ successMesssage: "Coupon deleted successfully" });
		} catch (error) {
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(couponActions.setCouponSubmiting(false));
		}
	});
}
export default function* couponSaga() {
	yield all([getAllCoupons(), addNewCoupon(), deleteCoupon(), updateCoupon()]);
}
