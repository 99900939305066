import { notification } from "antd";

export const sendToastNotification = ({ successMesssage, warningMessage, errormessage }) => {
	if (successMesssage) {
		notification.success({
			message: successMesssage,
		});
	}
	if (warningMessage) {
		notification.warning({
			message: warningMessage,
		});
	}
	if (errormessage) {
		notification.error({
			message: errormessage,
		});
	}
};
