import { Divider, Button, Form } from "antd";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { accessActions } from "../../redux/slices/accessviewer.slice";
import { useSelector } from "react-redux";
import AccessTable from "./Components/AccessTable";
import NewAddAccessViewerDrawer from "./Components/NewAddAccessViewerDrawer";
export default function AccessViewer() {
    const dispatch = useDispatch();
    const [visibleAccess, setVisibleAccess] = useState(false);
    const [editDataAccess, setEditDataAccess] = useState(null);
    const showDrawer = () => {
        setVisibleAccess(true);
    };
    const [accessTablePagination, setAccessTablePagination] = useState({
        total: 0,
        current: 1,
        size: 10,
    });
    const { totalUsers, totalUsersList, accessViewerPageLoading } = useSelector(
        (state) => state.access
    );
    const handleAccessEdit = (access) => {
        setVisibleAccess(true);
        setEditDataAccess(access);
    };
    useEffect(() => {
        dispatch(
            accessActions.getAccessViewerList({
                page: accessTablePagination.current,
                size: accessTablePagination.size,
            })
        );
    }, []);
    useEffect(() => {
        setAccessTablePagination((prev) => ({
            ...prev,
            total: totalUsers,
        }));
    }, []);

    // New Access Add
    const childrenroles = ["admin", "superAdmin"];
    const [accessList, setAccessList] = useState([
        "dashboard",
        "internship-projects",
        "training-programs",
        "workshops",
        "events",
        "blogs",
        "user",
        "payments",
        "instructors",
        "resources",
        "courses",
        "coupons",
        "jobs",
        "news-letters",
    ]);
    const [newAccessVisible, setNewAccessVisible] = useState(false);
    const [newOpen, setNewOpen] = useState(false);
    const [newaccessProjectForm] = Form.useForm();
    const newonClose = () => {
        newaccessProjectForm.resetFields();
        setNewAccessVisible(false);
        setNewOpen(false);
    };
    function getAccessInFormat(accessArray) {
        const array = [];
        for (var i = 0; i < accessArray.length; i++) {
            array.push({
                access: accessArray[i],
                subAccess: [],
            });
        }
        return array;
    }
    function getFilteredData(values) {
        const dataObject = {
            role: [values.roles],
            accesses: getAccessInFormat(values.access),
            user: values.user.value,
            status: "active",
        };
        return dataObject;
    }
    const newhandelFormSubmit = async (values) => {
        const getFinalAddAccessValue = getFilteredData(values);
        dispatch(accessActions.addAccess(getFinalAddAccessValue));
    };
    return (
        <>
            <div>
                <div className="flex justify-between p-6">
                    <div>
                        <h1 className="text-2xl">Access Viewer</h1>
                    </div>
                    <Button
                        onClick={() => {
                            setNewAccessVisible(true);
                            setNewOpen(true);
                        }}
                    >
                        Add New Access
                    </Button>

                    {newAccessVisible === true && (
                        <NewAddAccessViewerDrawer
                            visible={newAccessVisible}
                            open={newOpen}
                            onClose={newonClose}
                            handelFormSubmit={newhandelFormSubmit}
                            accessProjectForm={newaccessProjectForm}
                            childrenroles={childrenroles}
                            accessList={accessList}
                        />
                    )}
                </div>
                <Divider />
                <AccessTable
                    data={totalUsersList}
                    loading={accessViewerPageLoading}
                    onEditaccessRow={handleAccessEdit}
                />
            </div>
        </>
    );
}
