import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	instructors: [],
	loading: {
		instructors: false,
		instructorSubmiting: false,
	},
};
const instructorSlice = createSlice({
	name: "instructor",
	initialState,
	reducers: {
		getAllInstructors() {},
		addnewInstructor() {},
		updateInstructor() {},
		deleteInstructor() {},
		saveInstructor(state, action) {
			state.instructors = action.payload;
		},
		instructorLoading(state, action) {
			state.loading.instructors = action.payload;
		},
		instructorSubmiting(state, action) {
			state.loading.instructorSubmiting = action.payload;
		},
	},
});
export const instructorReducer = instructorSlice.reducer;
export const instructorActions = instructorSlice.actions;
