import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    totalUsers: 0,
    totalUsersList: [],
    accessViewerPageLoading: false,
    myAccess: {},
};

const accessViewerSlice = createSlice({
    name: "access",
    initialState,
    reducers: {
        addAccess: (state, action) => {},
        getAccessViewerList: (state, action) => {},
        getMyAccess: (state, action) => {},
        updateAccess: (state, action) => {},
        saveAccess: (state, action) => {
            const { totalUsers, totalUsersList } = action.payload;
            state.totalUsers = totalUsers;
            state.totalUsersList = totalUsersList;
            state.accessViewerPageLoading = false;
        },
        saveMyAccess: (state, action) => {
            state.myAccess = action.payload;
        },
        setAccessLoading: (state, action) => {
            state.accessViewerPageLoading = action.payload;
        },
        deleteAccess: (state, action) => {},
    },
});

export const accessReducer = accessViewerSlice.reducer;
export const accessActions = accessViewerSlice.actions;
