import React, { lazy, Suspense, useState } from "react";
import {
    Card,
    Col,
    Layout,
    Menu,
    Row,
    Divider,
    List,
    Avatar,
    Image,
    Dropdown,
    Button,
} from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DashboardOutlined,
    ProjectOutlined,
    ToolOutlined,
    LogoutOutlined,
    DollarCircleOutlined,
    UsergroupAddOutlined,
    ReadOutlined,
    PullRequestOutlined,
    TagOutlined,
    ProfileOutlined,
    FileWordOutlined,
    DeploymentUnitOutlined,
    MacCommandOutlined,
    HighlightOutlined,
    BoldOutlined,
    MoneyCollectOutlined,
    CaretDownOutlined,
} from "@ant-design/icons";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { useRouteMatch } from "react-router-dom";
import newLogo from "../../assets/images/newLogo.png";
import logo from "../../assets/images/logo.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { authActions } from "../../redux/slices/auth.slice";
import InternshipProjects from "../InternshipProjects/InternshipProjects";
import { useEffect } from "react";
import { accessActions } from "../../redux/slices/accessviewer.slice";
import NewWorkshopDetails from "../Workshop-New/NewWorkshopDetails";
import NewPayments from "../NewPayments/NewPayments";
import NewTrainingProgram from "../NewTrainingProgram/NewTrainingProgram";
import NewTrainingProgramDetails from "../NewTrainingProgram/NewTrainingProgramDetails";

const Project = lazy(() => import("../Project/Project"));
const Workshop = lazy(() => import("../Workshop/Workshop"));
const ProjectDetails = lazy(() => import("../Project/ProjectDetails"));
const WorkshopDetails = lazy(() => import("../Workshop/WorkshopDetails"));
const Payments = lazy(() => import("../Payments/Payments"));
const Users = lazy(() => import("../Users/Users"));
const Instructors = lazy(() => import("../Instructors/Instructors"));
const Resources = lazy(() => import("../Resources/Resources"));
const Coupon = lazy(() => import("../Coupon/Coupon"));
const UdemyCourses = lazy(() => import("../UdemyCourses/UdemyCourses"));
const Jobs = lazy(() => import("../Jobs/Jobs"));
const NewsletterPage = lazy(() => import("../Newsletter/NewsletterPage"));
const AddOrEditBlog = lazy(() => import("../Blogs/AddOrEditBlog"));
const BlogsHomePage = lazy(() => import("../Blogs/BlogsHomePage"));
const MyBlogs = lazy(() => import("../Blogs/MyBlogs"));
const MyAccessPage = lazy(() => import("../MyAccess/MyAccessPage"));
const ReviewRequiredBlogsPage = lazy(() =>
    import("../Blogs/ReviewRequiredBlogsPage")
);
const Dashboard = lazy(() => import("../Dashboard/Dashboard"));
const AccessViewer = lazy(() => import("../AccessViewer/AccessViewer"));
const Merch = lazy(() => import("../Merch/Merch"));
const WorkshopNew = lazy(() => import("../Workshop-New/WorkshopNew"));
const CouponOld = lazy(() => import("../CouponOld/CouponOld"));
const { Header, Sider, Content } = Layout;
const ProductRegistrations = lazy(() =>
    import("../ProductRegistrations/ProductRegistrations")
);
const Home = () => {
    const [archiveVisible, setarchiveVisible] = useState(false);
    const { url } = useRouteMatch();
    const [collapsed, setcollapsed] = useState(false);
    const { currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const toggle = () => {
        setcollapsed(!collapsed);
    };
    const { totalUsers, totalUsersList, accessViewerPageLoading, myAccess } =
        useSelector((state) => state.access);
    useEffect(() => {
        if (Object.keys(myAccess).length === 0) {
            dispatch(accessActions.getMyAccess());
        }
    }, []);
    const checkMenuItem = (key) => {
        if (myAccess?.role?.includes("superAdmin")) {
            return true;
        }
        return myAccess?.accesses?.find((curr) => {
            return curr.access === key;
        });
    };

    const userMenu = (
        <Menu className="h-40 w-52 rounded-md">
            <Menu.Item key="0" className="mt-2">
                <Button
                    icon={<LogoutOutlined />}
                    className="w-full flex items-center justify-center"
                    onClick={() => dispatch(authActions.logOutUser())}
                >
                    Logout
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout className="main-layout">
            <Header className="app-header">
                <div className="flex items-center mt-1">
                    <img width="200px" src={newLogo} alt="logo" />

                    <div
                        onClick={toggle}
                        className="ml-3 cursor-pointer text-purple-300"
                    >
                        {collapsed ? (
                            <MenuUnfoldOutlined style={{ fontSize: "25px" }} />
                        ) : (
                            <MenuFoldOutlined style={{ fontSize: "25px" }} />
                        )}
                    </div>

                    <div className="ml-auto mr-4 cursor-pointer">
                        <Dropdown overlay={userMenu} trigger={["click"]}>
                            <Avatar
                                className="ant-dropdown-link"
                                size="large"
                                src={currentUser && currentUser.photo}
                            />
                        </Dropdown>
                    </div>
                </div>
            </Header>
            <Layout
                className={collapsed ? "body-element active" : "body-element"}
            >
                <Sider
                    className="side-nav"
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                >
                    <Menu mode="inline" defaultSelectedKeys={["1"]}>
                        <Menu.Item
                            className="border-b-2"
                            key="14"
                            icon={
                                <FileWordOutlined
                                    style={{ fontSize: "20px" }}
                                />
                            }
                        >
                            <Link to="/my-access">My Access</Link>
                        </Menu.Item>
                        {checkMenuItem("user") && (
                            <Menu.Item
                                className="border-b-2"
                                key="5"
                                icon={
                                    <UsergroupAddOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                            >
                                <Link to="/users">Users</Link>
                            </Menu.Item>
                        )}
                        {checkMenuItem("training-programs") && (
                            <Menu.Item
                                className="border-b-2"
                                key="2"
                                icon={
                                    <ProjectOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                            >
                                <Link to="/newTrainingPrograms">
                                    Training Programs
                                </Link>
                            </Menu.Item>
                        )}
                        {checkMenuItem("workshops") && (
                            <>
                                <Menu.Item
                                    className="border-b-2"
                                    key="16"
                                    icon={
                                        <ProjectOutlined
                                            style={{ fontSize: "20px" }}
                                        />
                                    }
                                >
                                    <Link to="/new_workshops">Workshops</Link>
                                </Menu.Item>
                            </>
                        )}

                        {checkMenuItem("payments") && (
                            <>
                                <Menu.Item
                                    className="border-b-2"
                                    key="19"
                                    icon={
                                        <DollarCircleOutlined
                                            style={{ fontSize: "20px" }}
                                        />
                                    }
                                >
                                    <Link to="/newpayments">Payments</Link>
                                </Menu.Item>
                            </>
                        )}
                        {checkMenuItem("instructors") && (
                            <Menu.Item
                                className="border-b-2"
                                key="6"
                                icon={
                                    <ReadOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                            >
                                <Link to="/instructors">Instructors</Link>
                            </Menu.Item>
                        )}
                        {checkMenuItem("coupon") && (
                            <>
                                <Menu.Item
                                    className="border-b-2"
                                    key="8"
                                    icon={
                                        <TagOutlined
                                            style={{ fontSize: "20px" }}
                                        />
                                    }
                                >
                                    <Link to="/coupons-New">Coupon</Link>
                                </Menu.Item>
                            </>
                        )}
                        {checkMenuItem("udemycourses") && (
                            <>
                                <Menu.Item
                                    className="border-b-2"
                                    key="21"
                                    icon={
                                        <TagOutlined
                                            style={{ fontSize: "20px" }}
                                        />
                                    }
                                >
                                    <Link to="/udemyCourses">Udemy Courses</Link>
                                </Menu.Item>
                            </>
                        )}
                        {checkMenuItem("blogs") && (
                            <Menu.Item
                                className="border-b-2"
                                key="11"
                                icon={
                                    <BoldOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                            >
                                <Link to="/blogs">Blogs</Link>
                            </Menu.Item>
                        )}
                        {checkMenuItem("payments") && (
                            <Menu.Item
                                className="border-b-2"
                                key="18"
                                icon={
                                    <MoneyCollectOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                            >
                                <Link to="/productRegistrations">
                                    Product-Registrations
                                </Link>
                            </Menu.Item>
                        )}
                        {checkMenuItem("jobs") && (
                            <Menu.Item
                                className="border-b-2"
                                key="9"
                                icon={
                                    <ProfileOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                            >
                                <Link to="/jobs">Jobs</Link>
                            </Menu.Item>
                        )}
                        {checkMenuItem("news-letters") && (
                            <Menu.Item
                                className="border-b-2"
                                key="10"
                                icon={
                                    <FileWordOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                            >
                                <Link to="/newsletter">
                                    News Letter
                                </Link>
                            </Menu.Item>
                        )}
                        {myAccess?.role?.includes("superAdmin") && (
                            <Menu.Item
                                className="border-b-2"
                                key="13"
                                icon={
                                    <MacCommandOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                            >
                                <Link to="/access">Access Viewer</Link>
                            </Menu.Item>
                        )}
                        <div
                            style={{
                                height: "10px",
                                backgroundColor: "#537FE7",
                                width: "100%",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "10px",
                                marginBottom: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setarchiveVisible(!archiveVisible);
                            }}
                        >
                            Archive
                            <CaretDownOutlined />
                        </div>
                        <div
                            style={{
                                height: "10px",
                                backgroundColor: "#537FE7",
                                width: "100%",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                        {archiveVisible && (
                            <>
                                {checkMenuItem("dashboard") && (
                                    <Menu.Item
                                        className="border-b-2"
                                        key="1"
                                        icon={
                                            <DashboardOutlined
                                                style={{ fontSize: "20px" }}
                                            />
                                        }
                                    >
                                        <Link to={`/dashboard`}>Dashboard</Link>
                                    </Menu.Item>
                                )}
                                {checkMenuItem("training-programs") && (
                                    <Menu.Item
                                        className="border-b-2"
                                        key="2"
                                        icon={
                                            <ProjectOutlined
                                                style={{ fontSize: "20px" }}
                                            />
                                        }
                                    >
                                        <Link to="/project">
                                            Training Programs
                                        </Link>
                                    </Menu.Item>
                                )}
                                {checkMenuItem("workshops") && (
                                    <>
                                        <Menu.Item
                                            className="border-b-2"
                                            key="3"
                                            icon={
                                                <ToolOutlined
                                                    style={{ fontSize: "20px" }}
                                                />
                                            }
                                        >
                                            <Link to="/workshop">
                                                Workshops
                                            </Link>
                                        </Menu.Item>
                                    </>
                                )}
                                {checkMenuItem("payments") && (
                                    <>
                                        <Menu.Item
                                            className="border-b-2"
                                            key="4"
                                            icon={
                                                <DollarCircleOutlined
                                                    style={{ fontSize: "20px" }}
                                                />
                                            }
                                        >
                                            <Link to="/payments">Payment</Link>
                                        </Menu.Item>
                                    </>
                                )}

                                {checkMenuItem("resources") && (
                                    <Menu.Item
                                        className="border-b-2"
                                        key="7"
                                        icon={
                                            <PullRequestOutlined
                                                style={{ fontSize: "20px" }}
                                            />
                                        }
                                    >
                                        <Link to="/resources">Resources</Link>
                                    </Menu.Item>
                                )}
                                {checkMenuItem("coupon") && (
                                    <>
                                        <Menu.Item
                                            className="border-b-2"
                                            key="17"
                                            icon={
                                                <TagOutlined
                                                    style={{ fontSize: "20px" }}
                                                />
                                            }
                                        >
                                            <Link to="/coupons">
                                                Old-Coupon
                                            </Link>
                                        </Menu.Item>
                                    </>
                                )}
                                {checkMenuItem("internship-projects") && (
                                    <Menu.Item
                                        className="border-b-2"
                                        key="12"
                                        icon={
                                            <HighlightOutlined
                                                style={{ fontSize: "20px" }}
                                            />
                                        }
                                    >
                                        <Link to="/internship-projects">
                                            Internship Projects
                                        </Link>
                                    </Menu.Item>
                                )}
                            </>
                        )}
                    </Menu>
                </Sider>

                <Layout className="app-layout">
                    <div className="app-content">
                        <Content className="site-layout-background">
                            <Switch>
                                <Suspense fallback={Loader}>
                                    <Route
                                        exact
                                        path={`/dashboard`}
                                        component={Dashboard}
                                    />
                                    <Route
                                        exact
                                        path="/project"
                                        component={Project}
                                    />
                                    <Route
                                        exact
                                        path="/project/:id"
                                        component={ProjectDetails}
                                    />
                                    <Route
                                        exact
                                        path="/new_workshops"
                                        component={WorkshopNew}
                                    />
                                    <Route
                                        exact
                                        path="/newpayments"
                                        component={NewPayments}
                                    />
                                    <Route
                                        exact
                                        path="/new_workshops/:id"
                                        component={NewWorkshopDetails}
                                    />
                                    <Route
                                        exact
                                        path="/workshop/:id"
                                        component={WorkshopDetails}
                                    />
                                    <Route
                                        exact
                                        path="/workshop"
                                        component={Workshop}
                                    />
                                    <Route
                                        exact
                                        path="/payments"
                                        component={Payments}
                                    />
                                    <Route
                                        exact
                                        path="/users"
                                        component={Users}
                                    />
                                    <Route
                                        exact
                                        path="/instructors"
                                        component={Instructors}
                                    />
                                    <Route
                                        exact
                                        path="/resources"
                                        component={Resources}
                                    />
                                    <Route
                                        exact
                                        path="/coupons-New"
                                        component={Coupon}
                                    />
                                    <Route
                                        exact
                                        path="/udemyCourses"
                                        component={UdemyCourses}
                                    />
                                    <Route
                                        exact
                                        path="/newTrainingPrograms"
                                        component={NewTrainingProgram}
                                    />
                                    <Route
                                        exact
                                        path="/newTrainingProgram/:id"
                                        component={NewTrainingProgramDetails}
                                    />
                                    <Route
                                        exact
                                        path="/coupons"
                                        component={CouponOld}
                                    />
                                    <Route
                                        exact
                                        path="/jobs"
                                        component={Jobs}
                                    />
                                    <Route
                                        exact
                                        path="/newsletter"
                                        component={NewsletterPage}
                                    />
                                    <Route
                                        exact
                                        path="/blogs"
                                        component={BlogsHomePage}
                                    />
                                    <Route
                                        exact
                                        path="/blogs/new"
                                        component={AddOrEditBlog}
                                    />
                                    <Route
                                        exact
                                        path="/blogs/review-required"
                                        component={ReviewRequiredBlogsPage}
                                    />
                                    <Route
                                        exact
                                        path="/blogs/edit/:id"
                                        component={AddOrEditBlog}
                                    />
                                    <Route
                                        exact
                                        path="/blogs/my-blogs"
                                        component={MyBlogs}
                                    />
                                    <Route
                                        exact
                                        path="/internship-projects"
                                        component={InternshipProjects}
                                    />
                                    <Route
                                        exact
                                        path="/access"
                                        component={AccessViewer}
                                    />
                                    <Route
                                        exact
                                        path="/my-access"
                                        component={MyAccessPage}
                                    />
                                    <Route
                                        exact
                                        path="/productRegistrations"
                                        component={ProductRegistrations}
                                    />
                                </Suspense>
                            </Switch>
                        </Content>
                    </div>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default Home;
