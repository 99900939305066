import { Button, Divider, Empty, message } from "antd";
import React, { useState } from "react";
import { FileAddOutlined } from "@ant-design/icons";
import EditInternshipProject from "./EditInternshipProject";
import { useDispatch } from "react-redux";
import { internshipProjectActions } from "../../redux/slices/internshipProject";
import { useEffect } from "react";
import ProjectCard from "./Components/ProjectCard";
import { useSelector } from "react-redux";
export default function InternshipProjects() {
    const dispatch = useDispatch();
    const { internshipProjects } = useSelector(
        (state) => state.internshipProject
    );
    const [visible, setVisible] = useState(false);
    const [editData, setEditData] = useState(null);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        setEditData(null);
    };
    const onSubmit = (values) => {
        const sanitizedValues = {
            ...values,
            mentors: values.mentors.map((mentor) => mentor.value),
            collaborators: values.collaborators.map(
                (collaborator) => collaborator.value
            ),
        };
        console.log("sani", sanitizedValues);
        console.log("edit", editData);
        if (!editData) {
            dispatch(
                internshipProjectActions.addInternshipProject(sanitizedValues)
            );
            onClose();
        } else {
            dispatch(
                internshipProjectActions.updateInternshipProject({
                    data: sanitizedValues,
                    id: editData._id,
                })
            );
            onClose();
            setEditData(null);
        }
    };
    const handelProjectDelete = (id) => {
        dispatch(internshipProjectActions.deleteInternshipProject(id));
    };
    const handelEditProject = (id) => {
        const filteredProject = internshipProjects.filter(
            (project) => project._id === id
        );
        setEditData(filteredProject[0]);
        setVisible(true);
    };
    const handelStatusChange = ({ value, id }) => {
        setEditData((prev) => ({ ...prev, isPublished: value }));
        dispatch(internshipProjectActions.publishInternshipProject(id));
    };
    useEffect(() => {
        dispatch(
            internshipProjectActions.getAllInternshipProjects({
                pageNo: 1,
                size: 10,
            })
        );
    }, []);
    return (
        <div>
            <div className="flex justify-between p-6">
                <div>
                    <h1 className="text-2xl">Projects</h1>
                </div>
                <Button
                    className="flex justify-center items-center"
                    icon={<FileAddOutlined />}
                    onClick={showDrawer}
                >
                    New Project
                </Button>
                {visible && (
                    <EditInternshipProject
                        visible={visible}
                        onClose={onClose}
                        data={editData}
                        handelFormSubmit={onSubmit}
                        onStatusChange={handelStatusChange}
                    />
                )}
            </div>
            <Divider />
            {internshipProjects.length === 0 && (
                <Empty description="No Project" />
            )}

            <div class="grid grid-cols-3 gap-4">
                {internshipProjects.map((project) => (
                    <ProjectCard
                        project={project}
                        key={project._id}
                        onDeleteClick={handelProjectDelete}
                        onEditClick={handelEditProject}
                    />
                ))}
            </div>
        </div>
    );
}
