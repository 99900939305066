import { ironManInstance } from "./apiService";

const getAllProductRegistrations = ({ pageNo, size }) =>
    ironManInstance.get(
        `/product-registrations?${size ? `size=${size}` : ""}${
            pageNo ? `&pageNo=${pageNo}` : ""
        }`
    );
const deleteProductRegistration = (id) =>
    ironManInstance.delete(`/product-registrations/${id}`);

export default {
    getAllProductRegistrations,
    deleteProductRegistration,
};
