import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Row,
    Space,
    Select,
    Tag,
} from "antd";
import React from "react";
import FechUserInput from "../../../Components/Shared/FetchUserInput";

export default function NewAddAccessViewerDrawer({
    visible,
    onClose,
    handelFormSubmit,
    accessProjectForm,
    curraccess,
    childrenroles,
    accessList,
}) {
    return (
        <>
            {visible === true ? (
                <>
                    <Drawer
                        title="Edit Access"
                        placement="right"
                        width={500}
                        onClose={onClose}
                        visible={visible}
                        extra={
                            <Space>
                                <Button onClick={onClose}>Cancel</Button>
                            </Space>
                        }
                    >
                        <Form
                            name="access-viewer-form"
                            onFinish={handelFormSubmit}
                            form={accessProjectForm}
                            layout="vertical"
                            scrollToFirstError={true}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="user"
                                        label="User"
                                        rules={[
                                            {
                                                required: true,
                                                message: "User is required",
                                            },
                                        ]}
                                    >
                                        <FechUserInput />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="roles" label="Roles">
                                        <Select
                                            style={{
                                                width: "100%",
                                                paddingTop: "20px",
                                            }}
                                            placeholder="Edit roles"
                                        >
                                            {childrenroles?.map((role, key) => {
                                                return (
                                                    <>
                                                        <Tag
                                                            value={role}
                                                            color={"volcano"}
                                                        >
                                                            {role}
                                                        </Tag>
                                                    </>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="access"
                                        label="Access"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Access is required",
                                            },
                                        ]}
                                    >
                                        <Select
                                            mode="tags"
                                            style={{
                                                width: "100%",
                                                paddingTop: "20px",
                                            }}
                                            placeholder="access "
                                        >
                                            {accessList?.map((access, key) => {
                                                return (
                                                    <>
                                                        <Tag
                                                            value={access}
                                                            color={"volcano"}
                                                        >
                                                            {access}
                                                        </Tag>
                                                    </>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Drawer>
                </>
            ) : (
                <></>
            )}
        </>
    );
}
