import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	resources: [],
	loading: {
		resources: false,
	},
};
const resourceSlice = createSlice({
	name: "resource",
	initialState,
	reducers: {
		getAllResources() {},
		addNewResource() {},
		saveAllResources(state, action) {
			state.resources = action.payload;
		},
		setResourcesLoading(state, action) {
			state.loading.resources = action.payload;
		},
	},
});

export const resourceReducer = resourceSlice.reducer;
export const resourceActions = resourceSlice.actions;
