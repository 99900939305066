import { ironManInstance } from "./apiService";

const addNewBlog = (blogDetails) => {
    return ironManInstance.post("/blogs", blogDetails);
};
const getAllBlogs = (pageNo, size) => {
    return ironManInstance.get(`/blogs?pageNo=${pageNo}&size=${size}`);
};

const getMyBlogs = () => {
    return ironManInstance.get("/blogs/my-blogs");
};

const getBlogByBlogId = (blogId) => {
    return ironManInstance.get(`/blogs/get-blog/${blogId}`);
};
const updateBlog = (blogId, blogDetails) => {
    return ironManInstance.put(`/blogs/${blogId}`, blogDetails);
};
const publishBlog = (blogId, { isPublished = true }) => {
    return ironManInstance.put(`/blogs/${blogId}/publish`, { isPublished });
};
const getPendingBlogs = () => {
    return ironManInstance.get("/blogs/pending");
};
const approveBlog = (blogId) => {
    return ironManInstance.put(`/blogs/${blogId}/approve`);
};
const deleteBlog = (blogId) => {
    return ironManInstance.delete(`/blogs/${blogId}`);
};
export default {
    addNewBlog,
    getMyBlogs,
    getBlogByBlogId,
    updateBlog,
    publishBlog,
    getPendingBlogs,
    approveBlog,
    deleteBlog,
    getAllBlogs,
};
