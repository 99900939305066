import { all, takeEvery, put } from "redux-saga/effects";
import jobsService from "../../services/jobsService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { jobsActions } from "../slices/jobs.slice";
export function* getAllJobs() {
    yield takeEvery(jobsActions.getAllJobs.type, function* (action) {
        try {
            const { pageNo, size } = action.payload;
            yield put(jobsActions.setJobLoading(true));
            const { data } = yield jobsService.getAllJobs({
                pageNo,
                size,
            });
            yield put(jobsActions.saveJobs(data));
            yield put(jobsActions.setJobLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(jobsActions.setJobLoading(false));
        }
    });
}
export function* AddNewJob() {
    yield takeEvery(jobsActions.addJob.type, function* (action) {
        try {
            yield put(jobsActions.setJobLoading(true));
            yield jobsService.createJob(action.payload);
            yield put(
                jobsActions.getAllJobs({
                    pageNo: 1,
                    size: 20,
                })
            );
            yield put(jobsActions.setJobLoading(false));
            sendToastNotification({ successMesssage: "Job Created Successfully" });
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(jobsActions.setJobLoading(false));
        }
    });
}
export function* updateJob() {
    yield takeEvery(jobsActions.updateJob.type, function* (action) {
        try {
            const { id, jobDetails } = action.payload;

            yield put(jobsActions.setJobLoading(true));
            yield jobsService.updateJob(id, jobDetails);
            yield put(
                jobsActions.getAllJobs({
                    pageNo: 1,
                    size: 20,
                })
            );
            yield put(jobsActions.setJobLoading(false));
            sendToastNotification({ successMesssage: "Job updated successfully" });
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(jobsActions.setJobLoading(false));
        }
    });
}

export function* DeleteJob() {
    yield takeEvery(jobsActions.deleteJob.type, function* (action) {
        try {
            yield put(jobsActions.setJobLoading(true));
            yield jobsService.deleteJob(action.payload);
            yield put(
                jobsActions.getAllJobs({
                    pageNo: 1,
                    size: 20,
                })
            );
            yield put(jobsActions.setJobLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(jobsActions.setJobLoading(false));
        }
    });
}

export default function* jobsSaga() {
    yield all([getAllJobs(), AddNewJob(), DeleteJob(), updateJob()]);
}
