import { all, takeEvery, put } from "redux-saga/effects";
import newsLetterService from "../../services/newsLetterService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { newsletterActions } from "../slices/newsletter.slice";

export function* getAllSubscribers() {
    yield takeEvery(newsletterActions.getAllSubscribers.type, function* (action) {
        try {
            // const { pageNo, size } = action.payload;
            yield put(newsletterActions.setSubscribersLoading(true));
            const { data } = yield newsLetterService.getAllNewsLetterSubscriber();
            yield put(newsletterActions.saveSubscribers(data));
            yield put(newsletterActions.setSubscribersLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({ errormessage: error.response.data.message });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(newsletterActions.setSubscribersLoading(false));
        }
    });
}

export default function* newsletterSaga() {
    yield all([getAllSubscribers()]);
}
