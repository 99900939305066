import { ironManInstance } from "./apiService";
const udemyCoursesEndpoint = "/udemy-courses";

// const getAllUdemyCourses = () => ironManInstance.get(`${udemyCoursesEndpoint}/all`);
const getAllUdemyCourses = () => ironManInstance.get(`${udemyCoursesEndpoint}/admin/all`);
console.log("IN SERVICE", getAllUdemyCourses.data)
const addNewUdemyCourse = (udemyCourseInfo) =>
    ironManInstance.post(udemyCoursesEndpoint, udemyCourseInfo);
const deleteUdemyCourse = (udemyCourseId) =>
    ironManInstance.delete(`${udemyCoursesEndpoint}/${udemyCourseId}`);
const updateUdemyCourse = (udemyCourseId, udemyCourseData) =>
    ironManInstance.patch(`${udemyCoursesEndpoint}/${udemyCourseId}`, udemyCourseData);
export default {
    getAllUdemyCourses,
    addNewUdemyCourse,
    deleteUdemyCourse,
    updateUdemyCourse,
};
