import { ironManInstance } from "./apiService";
const couponEndpoint = "/coupons";

const getAllTokens = () => ironManInstance.get(couponEndpoint);
const addnewtoken = (couponInfo) =>
    ironManInstance.post(couponEndpoint, couponInfo);
const deleteCoupon = (tokenId) =>
    ironManInstance.delete(`${couponEndpoint}/${tokenId}`);
const updateCoupon = (tokenId, tokenData) =>
    ironManInstance.patch(`${couponEndpoint}/${tokenId}`, tokenData);
export default {
    getAllTokens,
    addnewtoken,
    deleteCoupon,
    updateCoupon,
};
