import { all, takeEvery, put } from "redux-saga/effects";
import accessService from "../../services/accessService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { accessActions } from "../slices/accessviewer.slice";

export function* getAccessViewerList() {
    yield takeEvery(accessActions.getAccessViewerList.type, function* (action) {
        try {
            const { pageNo, size } = action.payload;
            yield put(accessActions.setAccessLoading(true));
            const data = yield accessService.getAccessViewerList({
                pageNo,
                size,
            });
            // console.log(data);
            const accessData = {
                totalUsers: data.data.length,
                totalUsersList: data.data,
            };
            // console.log(accessData);
            yield put(accessActions.saveAccess(accessData));
            yield put(accessActions.setAccessLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(accessActions.setAccessLoading(false));
        }
    });
}
export function* updateAccess() {
    yield takeEvery(accessActions.updateAccess.type, function* (action) {
        try {
            const { id, accessDetails } = action.payload;
            yield put(accessActions.setAccessLoading(true));
            yield accessService.updateAccess(id, accessDetails);
            yield put(
                accessActions.getAccessViewerList({
                    pageNo: 1,
                    size: 20,
                })
            );
            yield put(accessActions.setAccessLoading(false));
            sendToastNotification({
                successMesssage: "Access updated successfully",
            });
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(accessActions.setAccessLoading(false));
        }
    });
}
export function* addAccess() {
    yield takeEvery(accessActions.addAccess.type, function* (action) {
        try {
            yield put(accessActions.setAccessLoading(true));
            yield accessService.addAccess(action.payload);
            yield put(
                accessActions.getAccessViewerList({
                    pageNo: 1,
                    size: 20,
                })
            );
            yield put(accessActions.setAccessLoading(false));
            sendToastNotification({
                successMesssage: "Access Created Successfully",
            });
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(accessActions.setAccessLoading(false));
        }
    });
}
export function* deleteAccess() {
    yield takeEvery(accessActions.deleteAccess.type, function* (action) {
        try {
            yield put(accessActions.setAccessLoading(true));
            yield accessService.deleteAccess(action.payload);
            yield put(
                accessActions.getAccessViewerList({
                    pageNo: 1,
                    size: 20,
                })
            );
            yield put(accessActions.setAccessLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(accessActions.setAccessLoading(false));
        }
    });
}
export function* getMyAccess() {
    yield takeEvery(accessActions.getMyAccess.type, function* (action) {
        try {
            const { data } = yield accessService.getMyAccess();
            yield put(accessActions.saveMyAccess(data));
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(accessActions.setAccessLoading(false));
        }
    });
}
export default function* accessSaga() {
    yield all([
        getAccessViewerList(),
        updateAccess(),
        addAccess(),
        deleteAccess(),
        getMyAccess(),
    ]);
}
