import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	udemyCourses: [],
	loading: {
		udemyCourses: false,
		createUdemyCourses: false,
	},
};

const udemyCoursesSlice = createSlice({
	name: "udemyCourses",
	initialState,
	reducers: {
		addNewUdemyCourse() {},
		editUdemyCourse() {},
		deleteUdemyCourse() {},
		getAllUdemyCourses() {},
		updateUdemyCourse() {},
		saveUdemyCourse(state, action) {
			state.udemyCourses = action.payload;
		},
		setUdemyCourseLoading(state, action) {
			state.loading.udemyCourses = action.payload;
		},
		setUdemyCourseSubmitting(state, action) {
			state.loading.createUdemyCourses = action.payload;
		},
	},
});

export const udemyCoursesReducer = udemyCoursesSlice.reducer;
export const udemyCoursesActions = udemyCoursesSlice.actions;
