import { all, put, takeEvery } from "redux-saga/effects";
import productRegistrationsService from "../../services/productRegistrationsService";

import { sendToastNotification } from "../../utils/sendToastNotification";
import { productRegistrationsActions } from "../slices/productRegistrations.slice";

export function* getProductRegistrations() {
    yield takeEvery(
        productRegistrationsActions.getAllProductRegistrations.type,
        function* (action) {
            try {
                const { pageNo, size } = action.payload;
                const { data } =
                    yield productRegistrationsService.getAllProductRegistrations(
                        {
                            pageNo,
                            size,
                        }
                    );
                yield put(
                    productRegistrationsActions.saveProductRegistrations(data)
                );
            } catch (error) {
                console.log(error);
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
            }
        }
    );
}
export function* deleteProductRegistration() {
    yield takeEvery(
        productRegistrationsActions.deleteProductRegistration.type,
        function* (action) {
            try {
                yield productRegistrationsService.deleteProductRegistration(
                    action.payload
                );
                yield put(
                    productRegistrationsActions.getAllProductRegistrations()
                );
                sendToastNotification({
                    successMesssage:
                        "Product Registration deleted successfully",
                });
            } catch (error) {
                console.log(error);
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
            }
        }
    );
}

export default function* productRegistrationsSaga() {
    yield all([getProductRegistrations(), deleteProductRegistration()]);
}
