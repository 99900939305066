import { Card, Skeleton, Table, Tag, Form, Popconfirm } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import AccessViewerDrawer from "./AccessViewerDrawer";
import { accessActions } from "../../../redux/slices/accessviewer.slice";
const childrenroles = ["admin", "superAdmin"];

function AccessTable({
    data,
    loading,
    onEditaccessRow,
    pagination = {},
    title,
    onRow,
    ...otherprops
}) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [curraccess, setCurraccess] = useState({});
    const [visible, setVisible] = useState(false);
    const DeleteAccess = (id) => {
        dispatch(accessActions.deleteAccess(id));
    };
    const columns = [
        {
            title: "Name",
            dataIndex: "user",
            key: "user",
            render: (item) => (
                <>
                    <h1>{item.name}</h1>
                </>
            ),
        },
        {
            title: "Phone No.",
            dataIndex: "user",
            key: "user",
            render: (item) => (
                <>
                    <h1>{item.phone}</h1>
                </>
            ),
        },
        {
            title: "Email",
            dataIndex: "user",
            key: "user",
            render: (item) => (
                <>
                    <h1>{item.email}</h1>
                </>
            ),
        },
        {
            title: "Roles",
            key: "role",
            dataIndex: "role",
            render: (_, { role }) => (
                <>
                    {role.map((currrole, index) => {
                        return (
                            <Tag key={index} color={"geekblue"}>
                                {currrole.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: "Access",
            key: "accesses",
            dataIndex: "accesses",
            render: (_, { accesses }) => (
                <>
                    {accesses.map((curraccess, index) => {
                        return (
                            <Tag
                                key={index}
                                color={"volcano"}
                                style={{ borderRadius: "20%" }}
                            >
                                {curraccess.access.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <>
                    <a
                        href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            setCurraccess(record);
                            setOpen(true);
                            setVisible(true);
                        }}
                    >
                        Edit
                    </a>
                    <Popconfirm
                        title="Are you sure to delete this Access?"
                        onConfirm={() => DeleteAccess(record._id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a href="#" className="text-red-500 ml-4">
                            Delete
                        </a>
                    </Popconfirm>
                </>
            ),
        },
    ];

    const [accessList, setAccessList] = useState([
        "dashboard",
        "internship-projects",
        "training-programs",
        "workshops",
        "events",
        "blogs",
        "user",
        "payments",
        "instructors",
        "resources",
        "courses",
        "coupons",
        "jobs",
        "news-letters",
    ]);

    const [accessProjectForm] = Form.useForm();
    function getAccessInFormat(accessArray) {
        const array = [];
        for (var i = 0; i < accessArray.length; i++) {
            array.push({
                access: accessArray[i],
                subAccess: [],
            });
        }
        return array;
    }
    function getFilteredData(values) {
        const dataObject = {
            role:
                values.roles === undefined
                    ? curraccess.role
                    : [values.roles],
            accesses:
                values.access === undefined
                    ? curraccess.accesses
                    : getAccessInFormat(values.access),
            user: curraccess.user._id,
            status: curraccess.status,
        };
        return dataObject;
    }
    const handelFormSubmit = async (values) => {
        const GetFilteredDataValues = await getFilteredData(values);
        console.log("filter", GetFilteredDataValues);
        dispatch(
            accessActions.updateAccess({
                accessDetails: GetFilteredDataValues,
                id: curraccess._id,
            })
        );
    };
    const onClose = () => {
        accessProjectForm.resetFields();
        setVisible(false);
        setOpen(false);
    };
    return (
        <>
            <div>
                <Card className="rounded-md p-0 home-latest-txn">
                    <Skeleton active loading={false} paragraph={{ rows: 6 }}>
                        <Table
                            style={{
                                overflow: "auto",
                            }}
                            columns={columns}
                            dataSource={data}
                            pagination={pagination}
                            loading={loading}
                            onRow={onRow}
                        />
                    </Skeleton>
                </Card>
            </div>
            <AccessViewerDrawer
                visible={visible}
                open={open}
                onClose={onClose}
                handelFormSubmit={handelFormSubmit}
                accessProjectForm={accessProjectForm}
                curraccess={curraccess}
                childrenroles={childrenroles}
                accessList={accessList}
            />
        </>
    );
}

export default AccessTable;
