import { all, takeEvery, put } from "redux-saga/effects";
import newPaymentService from "../../services/newPaymentService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { NewpaymentActions } from "../slices/newPayments.slice";

export function* getPayments() {
    yield takeEvery(NewpaymentActions.getAllPayments.type, function* (action) {
        try {
            yield put(NewpaymentActions.setPaymentLoading(true));
            const { data } = yield newPaymentService.getAllPayments();
            yield put(NewpaymentActions.savePayments(data));
            yield put(NewpaymentActions.setPaymentLoading(false));
        } catch (error) {
            console.log(error);
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(NewpaymentActions.setPaymentLoading(false));
        }
    });
}

export default function* newpaymentPageSaga() {
    yield all([getPayments()]);
}
