import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    newTrainingProgramLoading: false,
    newTrainingProgram: [],
    submiting: false,
    error: "",
    loading: {
        payments: false,
        users: false,
        checkouts: false,
    },
    newTrainingProgramPayment: {
        payments: [],
        noOfEnrolledUsers: 0,
        paymentCount: 0,
        total: 0,
    },
};

const newTrainingProgramSlice = createSlice({
    name: "newTrainingProgram",
    initialState,
    reducers: {
        getAllNewTrainingPrograms() {},
        updateNewTrainingProgram() {},
        addNewNewTrainingProgram() {},
        getPaymentDetailsOfNewTrainingProgram(id) {},
        savePaymentDetails(state, action) {
            const payments = action.payload.payments;
            console.log("payments", payments.length);
            state.newTrainingProgramPayment.payments = payments;
            state.newTrainingProgramPayment.noOfEnrolledUsers =
                payments?.length;
            state.newTrainingProgramPayment.total = payments
                .map((payment) => payment.amount)
                .reduce((a, b) => a + b, 0);
            state.newTrainingProgramPayment.paymentCount = payments?.length;
        },
        saveNewTrainingPrograms(state, action) {
            state.newTrainingProgram = action.payload;
        },
    },
});

export const newTrainingProgramActions = newTrainingProgramSlice.actions;
export const newTrainingProgramReducer = newTrainingProgramSlice.reducer;
