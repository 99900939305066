import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    jobs: [],
    loading: {
        jobs: false,
        createJob: false,
        deleteJob: false,
    },
    count: 0,
};

const jobsSlice = createSlice({
    name: "jobs",
    initialState,
    reducers: {
        addJob: (state, action) => {},
        updateJob: (state, action) => {},
        deleteJob: (state, action) => {},
        saveJobs(state, action) {
            const { jobs, count } = action.payload;
            state.jobs = jobs;
            state.count = count;
        },
        getAllJobs(state, action) {},
        setJobLoading(state, action) {
            state.loading.jobs = action.payload;
        },
    },
});

export const jobsReducer = jobsSlice.reducer;
export const jobsActions = jobsSlice.actions;
