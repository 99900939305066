import {
    Button,
    Col,
    Drawer,
    Input,
    Row,
    Space,
    Form,
    Select,
    DatePicker,
} from "antd";

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function PaymentMissingDrawer({
    onDrawerClose,
    visible,
    handelFormSubmit,
    form,
}) {
    
    const { workshops } = useSelector((state) => state.workshop);
    const { projects } = useSelector((state) => state.project);
    useEffect(() => {
        form.resetFields();
    }, []);
    return (
        <Drawer
            title={"Add missing Payment details"}
            width={700}
            onClose={onDrawerClose}
            visible={visible}
            // bodyStyle={{ paddingBottom: 80 }}
            footer={
                <Space className="flex justify-end">
                    <Button onClick={onDrawerClose}>Cancel</Button>
                    <Button form="workshop-form" type="submit" htmlType="form">
                        Submit
                    </Button>
                </Space>
            }
        >
            <Form
                name="workshop-form"
                onFinish={handelFormSubmit}
                form={form}
                layout="vertical"
                scrollToFirstError={true}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="paymentId"
                            label="Razorpay Payment Id"
                            rules={[
                                {
                                    required: true,
                                    message: "Razorpay Payment Id is required",
                                },
                            ]}
                        >
                            <Input placeholder="Eg: pay_ISSno43xD84Th" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="orderId"
                            label="Razorpay Order Id"
                            rules={[
                                {
                                    required: true,
                                    message: "Razorpay Order Id is required",
                                },
                            ]}
                        >
                            <Input placeholder="Eg: order_ISSnSASGzLMMNU" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="amount"
                            label="Amount Paid"
                            rules={[
                                {
                                    required: true,
                                    message: "Amount Paid is required",
                                },
                            ]}
                        >
                            <Input type="number" placeholder="Eg: 1999" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="paidFor"
                            label="Payment Made for"
                            rules={[
                                {
                                    required: true,
                                    message: "Razorpay Order Id is required",
                                },
                            ]}
                        >
                            <Select
                                className="w-full"
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select Product type"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value="Workshop">
                                    Workshop
                                </Select.Option>
                                <Select.Option value="Project">
                                    Project
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="productId"
                            label="Choose Product"
                            rules={[
                                {
                                    required: true,
                                    message: "Product is required",
                                },
                            ]}
                        >
                            <Select
                                className="w-full"
                                // showSearch
                                style={{ width: 200 }}
                                placeholder="Select a product"
                                optionFilterProp="children"
                                // filterOption={(input, option) =>
                                // 	option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                // }>
                            >
                                <Select.OptGroup label="Project">
                                    {projects.map((project) => (
                                        <Select.Option
                                            key={project._id}
                                            value={project._id}
                                        >
                                            {project.title}
                                        </Select.Option>
                                    ))}
                                </Select.OptGroup>
                                <Select.OptGroup label="Workshop">
                                    {workshops.map((workshop) => (
                                        <Select.Option
                                            key={workshop._id}
                                            value={workshop._id}
                                        >
                                            {workshop.title}
                                        </Select.Option>
                                    ))}
                                </Select.OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="userId"
                            label="User Id of Database"
                            rules={[
                                {
                                    required: true,
                                    message: "User Id is required",
                                },
                            ]}
                        >
                            <Input placeholder="Eg: 619137a41c198f0016dac6c6(Mongodb id)" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="paidOn"
                            label="Payment made on"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please select date of payment made",
                                },
                            ]}
                        >
                            <DatePicker
                                showTime={{ format: "HH:mm" }}
                                format="YYYY-MM-DD HH:mm"
                                className="w-full"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default PaymentMissingDrawer;
