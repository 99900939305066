import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { NewWorkShopActions } from "../../../redux/slices/NewWorkshop.slice";
import { useEffect } from "react";
import { Button, DatePicker, Form, Input } from "antd";
import moment from "moment/moment";

function Settings({ Foundworkshop }) {
    const { id } = useParams();
    const dispatch = useDispatch();
    console.log("wk", Foundworkshop);
    const [workshopForm] = Form.useForm();
    useEffect(() => {
        workshopForm.setFieldsValue({
            ...Foundworkshop.workshopDuration,
        });
    }, [Foundworkshop]);

    const onFinish = (values) => {
        const updatedvalues = {
            workshopDuration: {
                certificate: values.certificate,
                contentAvailibility: values.contentAvailibility,
                doubtSession: values.doubtSession,
                duration: values.duration,
                eligibility: values.eligibility,
                prerequisites: values.prerequisites,
                schedule: values.schedule,
                sessionDuration: values.sessionDuration,
                sessionTiming: values.sessionTiming,
            },
        };
        console.log("updatedvalues", updatedvalues);
        dispatch(
            NewWorkShopActions.updateWorkshop({
                id: id,
                workshopData: updatedvalues,
            })
        );
    };

    return (
        <>
            <Form
                name="basic"
                initialValues={Foundworkshop.workshopDuration}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
                wrapperCol={{
                    span: 12,
                }}
            >
                <Form.Item
                    label="certificate"
                    name="certificate"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input certificate details of project",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="contentAvailibility"
                    name="contentAvailibility"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input contentAvailibility details of project",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="doubtSession"
                    name="doubtSession"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input doubtSession details of project",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="duration"
                    name="duration"
                    rules={[
                        {
                            required: true,
                            message: "Please input duration details of project",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="eligibility"
                    name="eligibility"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input eligibility details of project",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="prerequisites"
                    name="prerequisites"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input eligibility details of project",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="schedule"
                    name="schedule"
                    rules={[
                        {
                            required: true,
                            message: "Please input schedule details of project",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="sessionDuration"
                    name="sessionDuration"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input sessionDuration details of project",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="sessionTiming"
                    name="sessionTiming"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input sessionTiming details of project",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="ghost" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default Settings;
