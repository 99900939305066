exports.formatDate = (date) => {
    var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    };
    var today = new Date(date);
    return today.toLocaleDateString("en-US", options);

    // console.log(today.toLocaleDateString("en-US", options)); // Saturday, September 17, 2016
};
