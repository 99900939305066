import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productRegistrations: [],
    productRegistrationsCount: 0,
};

const productRegistrationsSlice = createSlice({
    name: "productRegistrations",
    initialState,
    reducers: {
        getAllProductRegistrations() {},
        saveProductRegistrations(state, action) {
            const { count, registrations } = action.payload;
            state.productRegistrations = registrations;
            state.productRegistrationsCount = count;
        },
        deleteProductRegistration() {},
    },
});

export const productRegistrationsReducer = productRegistrationsSlice.reducer;
export const productRegistrationsActions = productRegistrationsSlice.actions;
