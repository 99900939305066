import { Button, Card, Divider, Form, Tag, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { newTrainingProgramActions } from "../../redux/slices/newTrainingProgram.slice";
import NewTrainingProgramDrawer from "./Component/NewTrainingProgramDrawer";
import NewWorkshopService from "../../services/NewWorkshopService";
import newTrainingProgramService from "../../services/newTrainingProgramService";

function NewTrainingProgram() {
    const [drawerVisible, setdrawerVisible] = useState(false);
    const [currentActiveProgram, setcurrentActiveProgram] = useState();
    const dispatch = useDispatch();
    const { newTrainingProgram, loading, error, submiting } = useSelector(
        (state) => state.newTrainingProgram
    );
    const [ProgramForm] = Form.useForm();
    useEffect(() => {
        console.log("11");
        // console.log("newTrainingProgram", newTrainingProgram);
        dispatch(newTrainingProgramActions.getAllNewTrainingPrograms());
        ProgramForm.resetFields();
    }, []);
    const onDrawerClose = () => {
        setdrawerVisible(false);
        setcurrentActiveProgram(null);
    };
    const showDrawer = () => {
        setdrawerVisible(true);
    };

    const handelProgramCopy = (id) => {
        const allPrograms = [
            ...newTrainingProgram.active,
            ...newTrainingProgram.completed,
        ];
        const matchedProgram = allPrograms.filter(
            (program) => program._id === id
        );
        if (matchedProgram.length !== 0) {
            const wshop = matchedProgram[0];
            const data = {
                features: wshop.features?.map((feature) => ({ feature })),
                subTitle: wshop.subTitle,
                completed: wshop.completed,
                perks: wshop.perks?.map((perk) => ({ perk })),
                curriculums: wshop.curiculums.map((curriculum) => ({
                    ...curriculum,
                    descriptions: curriculum.descriptions.map(
                        (description) => ({
                            description,
                        })
                    ),
                })),
                batchId: wshop.batchId,
                faqs: wshop.faqs,
                description: wshop.description,
                duration: wshop.duration,
                images: wshop.image,
                instructors: wshop.instructors.map(
                    (instructor) => instructor._id
                ),
                prerequisites: wshop.prerequisites.map((prerequisite) => ({
                    prerequisite,
                })),
                regestration_fees: wshop.registrationFees,
                apply_before: moment(wshop.applyBefore),
                start_date: moment(wshop.startDate),
                title: wshop.title,
                total_seats: wshop.totalSeats,
                isRegistrationOpened: wshop.isRegistrationOpened,
                copiedId: wshop._id,
                sessionDuration: wshop.sessionDuration,
                sessionTiming: wshop.sessionTiming,
                schedule: wshop.schedule,
                eligibility: wshop.eligibility,
                doubtSession: wshop.doubtSession,
                contentAvailibility: wshop.contentAvailibility,
                certificate: wshop.certificate,
                projectStructureLink: wshop.projectStructureLink,
                programType: wshop.programType,
                introVideo: wshop.introVideo,
                averageRating: wshop.averageRating || 0,
                totalRatings: wshop.totalRatings || 0,
            };
            ProgramForm.setFieldsValue({
                ...data,
            });
            showDrawer(true);
        }
        console.log(id);
    };
    const handelProgramEdit = (id) => {
        const allPrograms = [
            ...newTrainingProgram.active,
            ...newTrainingProgram.completed,
        ];

        const matchedProgram = allPrograms.filter(
            (program) => program._id === id
        );
        console.log("matchedProgram", matchedProgram);

        if (matchedProgram.length !== 0) {
            const wshop = matchedProgram[0];
            setcurrentActiveProgram(wshop);
            console.log(wshop);
            const data = {
                features: wshop.features?.map((feature) => ({ feature })),
                subTitle: wshop.subTitle,
                completed: wshop.completed,
                perks: wshop.perks?.map((perk) => ({ perk })),
                curriculums: wshop.curiculums.map((curriculum) => ({
                    ...curriculum,
                    descriptions: curriculum.descriptions.map(
                        (description) => ({
                            description,
                        })
                    ),
                })),
                faqs: wshop.faqs,
                description: wshop.description,
                duration: wshop.duration,
                images: wshop.image,
                batchId: wshop.batchId,
                instructors: wshop.instructors.map(
                    (instructor) => instructor._id
                ),
                prerequisites: wshop.prerequisites.map((prerequisite) => ({
                    prerequisite,
                })),
                regestration_fees: wshop.registrationFees,
                apply_before: moment(wshop.applyBefore),
                start_date: moment(wshop.startDate),
                title: wshop.title,
                total_seats: wshop.totalSeats,
                isRegistrationOpened: wshop.isRegistrationOpened,
                sessionDuration: wshop.sessionDuration,
                sessionTiming: wshop.sessionTiming,
                schedule: wshop.schedule,
                eligibility: wshop.eligibility,
                doubtSession: wshop.doubtSession,
                contentAvailibility: wshop.contentAvailibility,
                certificate: wshop.certificate,
                projectStructureLink: wshop.projectStructureLink,
                programType: wshop.programType,
                introVideo: wshop.introVideo,
                averageRating: wshop.averageRating,
                totalRatings: wshop.totalRatings
            };
            ProgramForm.setFieldsValue({
                ...data,
            });
            showDrawer(true);
        }
    };
    const getValidInstructorId = (instructor) => {
        console.log("instructor", instructor);
        const data = [];
        for (var i = 0; i < instructor.length; i++) {
            if (instructor[i] !== undefined) data.push(instructor[i]);
        }
        console.log("data", data);
        return data;
    };
    const handelFormSubmit = async (values) => {
        console.log("values", values);
        const {
            completed,
            subTitle,
            curriculums,
            description,
            duration,
            images,
            batchId,
            instructors,
            prerequisites,
            regestration_fees,
            apply_before,
            start_date,
            title,
            total_seats,
            faqs,
            perks,
            isRegistrationOpened,
            features,
            copiedId,
            sessionTiming,
            sessionDuration,
            schedule,
            eligibility,
            doubtSession,
            contentAvailibility,
            certificate,
            projectStructureLink,
            programType,
            introVideo,
            averageRating,
            totalRatings
        } = values;
        const obj = {
            completed,
            subTitle,
            image: images,
            title,
            batchId,
            totalSeats: total_seats,
            registrationFees: regestration_fees,
            description,
            duration,
            applyBefore: moment(apply_before).format(),
            startDate: moment(start_date).format(),
            prerequisites: prerequisites.map(
                (prerequisite) => prerequisite.prerequisite
            ),
            curiculums: curriculums.map((curriculum) => ({
                ...curriculum,
                descriptions: curriculum.descriptions.map(
                    (description) => description.description
                ),
            })),
            instructors: getValidInstructorId(instructors),
            features: features.map((feature) => feature.feature),
            faqs,
            perks: perks.map((perk) => perk.perk),
            isRegistrationOpened,
            sessionTiming,
            sessionDuration,
            schedule,
            eligibility,
            doubtSession,
            contentAvailibility,
            certificate,
            projectStructureLink,
            programType,
            introVideo,
            averageRating,
            totalRatings
        };
        console.log("obj", obj);
        const resetForm = () => {
            if (submiting === false && !error) {
                ProgramForm.resetFields();
            }
            setcurrentActiveProgram(null);
            setdrawerVisible(false);
        };
        if (!currentActiveProgram) {
            // // checking if user has copied workshop or not
            // if (copiedId !== undefined) {
            //     const dataForDuration =
            //         await newTrainingProgramService.getTrainingProgramById(
            //             copiedId
            //         );
            //     // if user has copied workshop then we will get duration of that workshop
            //     //console.log("dataForDuration", dataForDuration);
            //     const getProgramDuration =
            //         dataForDuration.data.workshopDuration;
            //     // console.log("getworkshopDuration", getworkshopDuration);
            //     obj.workshopDuration = getworkshopDuration;
            // }
            console.log("add");
            dispatch(newTrainingProgramActions.addNewNewTrainingProgram(obj));
            resetForm();
        } else {
            dispatch(
                newTrainingProgramActions.updateNewTrainingProgram({
                    id: currentActiveProgram._id,
                    data: obj,
                })
            );
            resetForm();
        }
    };
    return (
        <div>
            <div className="flex justify-between mt-4 items-center">
                <p className="text-gray-600 font-bold text-xl pl-4">
                    Active Training Programs
                </p>
                <Button onClick={showDrawer}>Add New Training Program</Button>
                {drawerVisible && (
                    <NewTrainingProgramDrawer
                        visible={drawerVisible}
                        onDrawerClose={onDrawerClose}
                        handelFormSubmit={handelFormSubmit}
                        form={ProgramForm}
                        // submiting={submiting}
                        activeProgram={currentActiveProgram}
                        onCopyChange={handelProgramCopy}
                    />
                )}
            </div>
            <Divider />
            <div className="grid grid-cols-3 gap-3 ml-10 w-full">
                {newTrainingProgram?.active?.map((program) => (
                    <Card
                        key={program._id}
                        hoverable
                        style={{ width: 300 }}
                        cover={<img alt="example" src={program.image} />}
                    >
                        {/* <Card.Meta title={workshop.title} description={workshop.description} /> */}
                        <Link to={`/newTrainingProgram/${program._id}`}>
                            <div>
                                <Tag color="green">{program.batchId}</Tag>
                                <p className="text-gray-600 font-semibold">
                                    {program.title}
                                </p>
                                <Typography.Paragraph
                                    ellipsis={{
                                        rows: 4,
                                    }}
                                >
                                    {program.description}
                                </Typography.Paragraph>
                            </div>
                        </Link>

                        <Button
                            className="w-full flex-1 relative top-4"
                            onClick={() => {
                                handelProgramEdit(program._id);
                            }}
                        >
                            Edit Program
                        </Button>
                    </Card>
                ))}
            </div>
            <div className="flex justify-between mt-4 items-center">
                <p className=" font-bold text-xl pl-4 text-red-500">
                    Archived Programs
                </p>
            </div>
            <div className="grid grid-cols-3 gap-3 ml-10 w-full">
                {newTrainingProgram?.completed?.map((program) => (
                    <Card
                        key={program._id}
                        hoverable
                        style={{ width: 300 }}
                        cover={<img alt="example" src={program.image} />}
                    >
                        {/* <Card.Meta title={workshop.title} description={workshop.description} /> */}
                        <Link to={`/newTrainingProgram/${program._id}`}>
                            <div>
                                <p className="text-gray-600 font-semibold">
                                    {program.title}
                                </p>
                                <Typography.Paragraph
                                    ellipsis={{
                                        rows: 4,
                                    }}
                                >
                                    {program.description}
                                </Typography.Paragraph>
                            </div>
                        </Link>

                        <Button
                            className="w-full flex-1 relative top-4"
                            onClick={() => {
                                handelProgramEdit(program._id);
                            }}
                        >
                            Edit Program
                        </Button>
                    </Card>
                ))}
            </div>
        </div>
    );
}

export default NewTrainingProgram;
