import { ironManInstance } from "./apiService";

const getTrainingPrograms = () => {
    return ironManInstance.get("/training-program");
};
const postTrainingPrograms = (data) => {
    return ironManInstance.post("/training-program", data);
};

const getTrainingProgramById = (id) => {
    return ironManInstance.get(`/training-program/${id}`);
};

const updateTrainingProgram = (id, data) => {
    return ironManInstance.patch(`/training-program/${id}`, data);
};

const deleteTrainingProgram = (id) => {
    return ironManInstance.delete(`/training-program/${id}`);
};

const getTrainingProgramBySlug = (slug) => {
    return ironManInstance.get(`/training-program/slug/${slug}`);
};
const getPaymentDetailsOfNewTrainingProgram = (id) =>
    ironManInstance.get(`/payments?product=${id}`);
export default {
    getTrainingPrograms,
    postTrainingPrograms,
    getTrainingProgramById,
    updateTrainingProgram,
    deleteTrainingProgram,
    getTrainingProgramBySlug,
    getPaymentDetailsOfNewTrainingProgram,
};
