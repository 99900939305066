import { all, put, takeEvery } from "redux-saga/effects";
import projectService from "../../services/projectService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { projectActions } from "../slices/project.slice";

export function* getProjects() {
    yield takeEvery(projectActions.getAllProjects.type, function* (action) {
        try {
            yield put(projectActions.setProjectsLoading(true));
            const { data } = yield projectService.getAllProjects();
            yield put(projectActions.saveProjects(data));
            yield put(projectActions.setProjectsLoading(false));
        } catch (error) {
            console.log(error);
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
        }
    });
}

export function* createNewProject() {
    yield takeEvery(projectActions.addNewProject.type, function* (action) {
        try {
            yield put(projectActions.setFormSubmiting(true));
            yield projectService.addNewProject(action.payload);
            yield put(projectActions.getAllProjects());
            yield put(projectActions.setFormSubmiting(false));
            sendToastNotification({
                successMesssage: "Project created successfully",
            });
        } catch (error) {
            console.log(error);
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
                yield put(projectActions.setError(error.response.data.message));
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(projectActions.setFormSubmiting(false));
        }
    });
}

export function* updateWorkshop() {
    yield takeEvery(projectActions.updateProject.type, function* (action) {
        try {
            const { id, data } = action.payload;
            yield put(projectActions.setFormSubmiting(true));
            yield projectService.updateProject({ id, projectData: data });
            yield put(projectActions.getAllProjects());
            yield put(projectActions.setFormSubmiting(false));
            sendToastNotification({
                successMesssage: "Project Updated Successfully",
            });
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
                yield put(projectActions.setError(error.response.data.message));
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(projectActions.setFormSubmiting(false));
        }
    });
}
export function* updateBasicTimingDetails() {
    yield takeEvery(
        projectActions.updateBasicTimingDetails.type,
        function* (action) {
            try {
                const { id, data } = action.payload;
                yield put(projectActions.setFormSubmiting(true));
                yield projectService.updateBasicTimingDetails({
                    id,
                    data: data,
                });
                yield put(projectActions.getAllProjects());
                yield put(projectActions.setFormSubmiting(false));
                sendToastNotification({
                    successMesssage: "Project Updated Successfully",
                });
            } catch (error) {
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                    yield put(
                        projectActions.setError(error.response.data.message)
                    );
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(projectActions.setFormSubmiting(false));
            }
        }
    );
}
export function* getPaymentDetailsOfProject() {
    yield takeEvery(
        projectActions.getPaymentDetailsOfProject.type,
        function* (action) {
            try {
                const id = action.payload;

                yield put(
                    projectActions.setProjectPayment({ payments: [], total: 0 })
                );
                yield put(projectActions.paymentLoading(true));
                const { data } =
                    yield projectService.getProjectPaymentsByProjectId(id);
                yield put(projectActions.setProjectPayment(data));
                yield put(projectActions.paymentLoading(false));
            } catch (error) {
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(projectActions.paymentLoading(false));
            }
        }
    );
}
export function* getEnrollUsersOfProject() {
    yield takeEvery(
        projectActions.getEnrollUsersOfAProject.type,
        function* (action) {
            try {
                const id = action.payload;
                yield put(projectActions.enrolledUsersLoading(true));
                const { data } =
                    yield projectService.getProjecEnrolledUsersByProjectId(id);
                yield put(projectActions.setEnrolledUsers(data));
                yield put(projectActions.enrolledUsersLoading(false));
            } catch (error) {
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(projectActions.enrolledUsersLoading(false));
            }
        }
    );
}
export function* getProjectCheckouts() {
    yield takeEvery(
        projectActions.getProjectCheckoutsData.type,
        function* (action) {
            try {
                const id = action.payload;
                yield put(
                    projectActions.setProjectCheckouts({
                        checkouts: [],
                        count: 0,
                    })
                );
                yield put(projectActions.setCheckoutLoading(true));
                const { data } = yield projectService.getCheckoutByprojectId(
                    id
                );
                yield put(projectActions.setProjectCheckouts(data));
                yield put(projectActions.setCheckoutLoading(false));
            } catch (error) {
                if (error.response) {
                    sendToastNotification({
                        errormessage: error.response.data.message,
                    });
                } else {
                    sendToastNotification({ errormessage: error.message });
                }
                yield put(projectActions.enrolledUsersLoading(false));
            }
        }
    );
}

export default function* projectPageSaga() {
    yield all([
        getProjects(),
        updateWorkshop(),
        createNewProject(),
        getPaymentDetailsOfProject(),
        getEnrollUsersOfProject(),
        getProjectCheckouts(),
        updateBasicTimingDetails(),
    ]);
}
