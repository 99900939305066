import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: {
        subscribers: false,
    },
    subscribers: [],
};

const newsletterSlice = createSlice({
    name: "newsletter",
    initialState,
    reducers: {
        getAllSubscribers(state, action) {},
        saveSubscribers(state, action) {
            state.subscribers = action.payload;
        },
        setSubscribersLoading(state, action) {
            state.loading.subscribers = action.payload;
        },
    },
});

export const newsletterReducer = newsletterSlice.reducer;
export const newsletterActions = newsletterSlice.actions;
