import { ironManInstance } from "./apiService";

const getAllProjects = ({ size, pageNo }) => {
    return ironManInstance.get(
        `/internship-projects?${size ? `size=${size}` : ""}${pageNo ? `&pageNo=${pageNo}` : ""}`
    );
};

const addProject = (projectDetails) => {
    return ironManInstance.post("/internship-projects", projectDetails);
};

const updateProject = (id, projectDetails) => {
    return ironManInstance.put(`/internship-projects/${id}`, projectDetails);
};

const deleteProject = (id) => {
    return ironManInstance.delete(`/internship-projects/${id}`);
};

const publishProject = (id) => {
    return ironManInstance.patch(`/internship-projects/${id}/publish`);
};

export default {
    getAllProjects,
    addProject,
    updateProject,
    deleteProject,
    publishProject,
};
