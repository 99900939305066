import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	loading: {
		page: false,
		payments: false,
	},
	workshops: [],
	error: "",
	submiting: false,
	workshopPayments: {
		payments: [],
		totalAmount: 0,
		noOfEnrolledUsers: 0,
		noOfPaymentCollected: 0,
	},
};
const workshopSlice = createSlice({
	name: "workshop",
	initialState,
	reducers: {
		getAllWorkshop() {},
		addNewWorkshop() {},
		updateWorkshop() {},
		getProjectPayments() {},
		saveWorkshop(state, action) {
			state.workshops = action.payload;
		},
		setWorkshopLoading(state, action) {
			state.loading.page = action.payload;
		},
		setFormSubmiting(state, action) {
			state.submiting = action.payload;
		},
		setError(state, action) {
			state.error = action.payload;
		},
		setWorkshopPayments(state, action) {
			const { payments, totalAmount } = action.payload;
			state.workshopPayments.payments = payments;
			state.workshopPayments.noOfEnrolledUsers = payments.length;
			state.workshopPayments.totalAmount = totalAmount;
			state.workshopPayments.noOfPaymentCollected = payments.length;
		},
		setPaymentLoading(state, action) {
			state.loading.payments = action.payload;
		},
	},
});

export const workshopReducer = workshopSlice.reducer;
export const workshopActions = workshopSlice.actions;
