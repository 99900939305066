import React, { useState } from "react";
import { Avatar, Button, Input } from "antd";
import Compress from "browser-image-compression";
import { uploadFile } from "../../utils/uploadFile";
import { useEffect } from "react";

function FileUpload({ value = null, onChange }) {
    const inputref = React.useRef(null);
    const [file, setFile] = useState(value);
    const handleFileChange = async (event) => {
        try {
            const file = event.target.files[0];
            const compressedFile = await Compress(file, {
                maxSizeMB: 0.3,
                useWebWorker: true,
            });
            const newfile = new File([compressedFile], file.name, {
                type: file.type,
                lastModified: Date.now(),
            });
            const { url } = await uploadFile(newfile, "projects");
            setFile(url);
            onChange(url);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        if (!file) setFile(value);
    }, [value]);
    return (
        <div className="flex">
            {file && (
                <img
                    src={file}
                    alt="project"
                    style={{ width: "150px", height: "100px" }}
                />
            )}
            <Button className="ml-4" onClick={() => inputref.current?.click()}>
                Upload
            </Button>
            <input
                type="file"
                className="ml-4 hidden"
                onChange={handleFileChange}
                ref={inputref}
            ></input>
        </div>
    );
}

export default FileUpload;
