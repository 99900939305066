import {
    Button,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Select,
    Form,
    Input,
    Row,
    Space,
    Switch,
    Tooltip,
    Upload,
    Tag,
} from "antd";
import {
    MinusCircleOutlined,
    PlusOutlined,
    FormOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Compress from "browser-image-compression";
import { useDispatch, useSelector } from "react-redux";
import { instructorActions } from "../../../redux/slices/instructor.slice";
import { uploadFile } from "../../../utils/uploadFile";
import { Option } from "antd/lib/mentions";
import FileUpload from "../../../Components/Shared/FileUpload";
import { newTrainingProgramActions } from "../../../redux/slices/newTrainingProgram.slice";
import { NewWorkShopActions } from "../../../redux/slices/NewWorkshop.slice";

function NewTrainingProgramDrawer({
    onDrawerClose,
    visible,
    handelFormSubmit,
    form,
    submiting,
    activeProgram,
    onCopyChange,
}) {
    const { workshops } = useSelector((state) => state.NewWorkshop);
    const [copieProgram, setcopieProgram] = useState();
    const { newTrainingProgram } = useSelector(
        (state) => state.newTrainingProgram
    );
    const dispatch = useDispatch();
    const { instructors } = useSelector((state) => state.instructor);
    useEffect(() => {
        dispatch(instructorActions.getAllInstructors());
        dispatch(newTrainingProgramActions.getAllNewTrainingPrograms());
        dispatch(NewWorkShopActions.getAllWorkshop());
        if (!activeProgram) {
            form.resetFields();
        }
    }, [activeProgram]);

    return (
        <Drawer
            title={`${!activeProgram ? "Add new Program" : "Edit Program"}`}
            width={900}
            onClose={onDrawerClose}
            visible={visible}
            // bodyStyle={{ paddingBottom: 80 }}
            footer={
                <Space className="flex justify-end">
                    <Button onClick={onDrawerClose}>Cancel</Button>
                    <Button
                        form="Program-form"
                        type="submit"
                        htmlType="form"
                        loading={submiting}
                    >
                        Submit
                    </Button>
                </Space>
            }
        >
            {!activeProgram && (
                <Row>
                    <Select
                        placeholder="Select a Program to copy"
                        value={copieProgram}
                        className="w-full"
                        onChange={(value) => {
                            setcopieProgram(value);
                            onCopyChange(value);
                        }}
                    >
                        {[...newTrainingProgram.active, ...newTrainingProgram.completed].map((program) => (
                            <Select.Option value={program._id}>
                                {program.title}
                            </Select.Option>
                        ))}
                    </Select>
                </Row>
            )}
            <Form
                name="Program-form"
                onFinish={handelFormSubmit}
                form={form}
                layout="vertical"
                scrollToFirstError={true}
            >
                <p className="text-center text-gray-700 font-semibold">
                    Basic Details
                </p>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Program title",
                                },
                            ]}
                        >
                            <Input placeholder="Please enter user name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Program Description",
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder="Program Description"
                                autoSize={{ minRows: 3 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="subTitle"
                            label="Sub Title"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter subTitle",
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder="Program subTitle"
                                autoSize={{ minRows: 3 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="start_date"
                            label="Start Date"
                            rules={[
                                {
                                    required: true,
                                    message: "Please Choose Program Start date",
                                },
                            ]}
                        >
                            <DatePicker
                                showTime={{ format: "HH:mm" }}
                                format="YYYY-MM-DD HH:mm"
                                className="w-full"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="apply_before"
                            label="Apply Before"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please Choose Apply Before for Program Start",
                                },
                            ]}
                        >
                            <DatePicker
                                showTime={{ format: "HH:mm" }}
                                format="YYYY-MM-DD HH:mm"
                                className="w-full"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="duration"
                            label="Duration (in weeks)"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Program title",
                                },
                            ]}
                        >
                            <Input placeholder="Please enter Duration (in weeks)" />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="copiedId"
                            label="copiedId"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Program copiedId",
                                },
                            ]}
                        >
                            <Input placeholder="Please enter copiedId" />
                        </Form.Item>
                    </Col>
                </Row> */}
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="batchId"
                            label="Batch ID"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Program title",
                                },
                            ]}
                        >
                            <Input placeholder="Please enter the batchID" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="total_seats"
                            label="Total Seats"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter Total Seats",
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder="Please enter Total Seats"
                                defaultValue={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="regestration_fees"
                            label="Registration Fees (in INR)"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please enter Program Registration Fees (in INR)",
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder="Enter Program Registration Fees (in INR)"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="text-lg text-gray-600 pb-2">
                            Prerequisites :
                        </label>
                        <Form.List
                            name="prerequisites"
                            initialValue={[{ prerequisite: "" }]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(
                                        ({ key, name, ...restField }) => (
                                            <Row gutter={10} align="center">
                                                <Col span={20}>
                                                    <Form.Item
                                                        label={
                                                            "Prerequisite " +
                                                            (name + 1)
                                                        }
                                                        {...restField}
                                                        name={[
                                                            name,
                                                            "prerequisite",
                                                        ]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Missing Prerequisite",
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Prerequisite" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    {fields.length > 1 && (
                                                        <MinusCircleOutlined
                                                            className="mt-10"
                                                            onClick={() =>
                                                                remove(name)
                                                            }
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        )
                                    )}
                                    <Form.Item>
                                        <Col span={20}>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add New Prerequisite
                                            </Button>
                                        </Col>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <p className="text-center text-gray-700 font-semibold p-2">
                    Major Takeaways
                </p>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.List name="perks" initialValue={[{ perk: "" }]}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(
                                        ({ key, name, ...restField }) => (
                                            <Row gutter={10} align="center">
                                                <Col span={20}>
                                                    <Form.Item
                                                        label={
                                                            " Perks " +
                                                            (name + 1)
                                                        }
                                                        {...restField}
                                                        name={[name, "perk"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    " Perks" +
                                                                    (name + 1) +
                                                                    " required",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder={
                                                                " Perks " +
                                                                (name + 1)
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    {fields.length > 1 && (
                                                        <MinusCircleOutlined
                                                            className="mt-10"
                                                            onClick={() =>
                                                                remove(name)
                                                            }
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        )
                                    )}
                                    <Form.Item>
                                        <Col span={20}>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add New Features and Perks
                                            </Button>
                                        </Col>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Divider />
                <p className="text-center text-gray-700 font-semibold p-2">
                    What you will learn
                </p>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.List
                            name="features"
                            initialValue={[{ perk: "" }]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(
                                        ({ key, name, ...restField }) => (
                                            <Row gutter={10} align="center">
                                                <Col span={20}>
                                                    <Form.Item
                                                        label={
                                                            "Features " +
                                                            (name + 1)
                                                        }
                                                        {...restField}
                                                        name={[name, "feature"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Features" +
                                                                    (name + 1) +
                                                                    " required",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder={
                                                                "Features" +
                                                                (name + 1)
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    {fields.length > 1 && (
                                                        <MinusCircleOutlined
                                                            className="mt-10"
                                                            onClick={() =>
                                                                remove(name)
                                                            }
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        )
                                    )}
                                    <Form.Item>
                                        <Col span={20}>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add New Features and Perks
                                            </Button>
                                        </Col>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="images"
                            label="Images"
                            rules={[
                                {
                                    required: true,
                                    message: "Please add images",
                                },
                            ]}
                            valuePropName="value"
                        >
                            <FileUpload />
                            {/* <Upload
                                customRequest={(options) =>
                                    uploadImage(options, "Program")
                                }
                                listType="picture-card"
                                fileList={ProgramImages}
                                // onPreview={this.handlePreview}
                                // onChange={this.handleChange}
                            >
                                {uploadButton}
                            </Upload> */}
                        </Form.Item>
                    </Col>
                </Row>
                <p className="text-center text-gray-700 font-semibold p-2">
                    Instructors
                </p>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="instructors">
                            <Select
                                mode="tags"
                                style={{
                                    width: "100%",
                                    paddingTop: "20px",
                                }}
                                placeholder="instructors"
                                options={instructors?.map((instructor) => {
                                    return {
                                        label: instructor.name,
                                        value: instructor._id,
                                    };
                                })}
                            >
                                {instructors?.map((instructor) => {
                                    return (
                                        <Option
                                            key={instructor._id}
                                            value={instructor.name}
                                        >
                                            {instructor.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <p className="text-center text-gray-700 font-semibold p-2">
                    Program Curriculum{" "}
                </p>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="text-lg text-gray-600 pb-2">
                            Course Curriculums :
                        </label>
                        <Form.List
                            name="curriculums"
                            initialValue={[{ curriculum: "" }]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(
                                        ({ key, name, ...restField }) => (
                                            <Row
                                                gutter={10}
                                                align="center"
                                                key={key}
                                            >
                                                <Col
                                                    span={20}
                                                    className="border-2 p-2 my-3"
                                                >
                                                    <Form.Item
                                                        label={
                                                            "Curriculum " +
                                                            (name + 1) +
                                                            " Title"
                                                        }
                                                        {...restField}
                                                        name={[name, "title"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: `Curriculum ${
                                                                    name + 1
                                                                } title require`,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder={`Curriculum ${
                                                                name + 1
                                                            }`}
                                                        />
                                                    </Form.Item>
                                                    <Form.List
                                                        name={[
                                                            name,
                                                            "descriptions",
                                                        ]}
                                                        initialValue={[
                                                            {
                                                                content: "",
                                                            },
                                                        ]}
                                                    >
                                                        {(
                                                            fields,
                                                            { add, remove }
                                                        ) => (
                                                            <>
                                                                {fields.map(
                                                                    ({
                                                                        key,
                                                                        name: nn,
                                                                        ...restField
                                                                    }) => (
                                                                        <Row
                                                                            gutter={
                                                                                10
                                                                            }
                                                                        >
                                                                            <Col
                                                                                span={
                                                                                    20
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    label={`Curriculum ${
                                                                                        name +
                                                                                        1
                                                                                    } description ${
                                                                                        nn +
                                                                                        1
                                                                                    }`}
                                                                                    {...restField}
                                                                                    name={[
                                                                                        nn,
                                                                                        "description",
                                                                                    ]}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: `Curriculum ${name} description ${
                                                                                                nn +
                                                                                                1
                                                                                            } required`,
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Input.TextArea
                                                                                        placeholder={`Curriculum ${
                                                                                            nn +
                                                                                            1
                                                                                        }`}
                                                                                        autoSize={{
                                                                                            minRows: 3,
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            {nn !==
                                                                                0 && (
                                                                                <Col
                                                                                    span={
                                                                                        4
                                                                                    }
                                                                                >
                                                                                    <Tooltip
                                                                                        title={`Remove Curriculum ${
                                                                                            nn +
                                                                                            1
                                                                                        }`}
                                                                                    >
                                                                                        <MinusCircleOutlined
                                                                                            className="mt-16 text-red-500 font-bold"
                                                                                            onClick={() => {
                                                                                                console.log(
                                                                                                    nn -
                                                                                                        2
                                                                                                );
                                                                                                remove(
                                                                                                    Math.max(
                                                                                                        nn -
                                                                                                            2,
                                                                                                        0
                                                                                                    )
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </Tooltip>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    )
                                                                )}

                                                                <Form.Item>
                                                                    <Col
                                                                        span={
                                                                            20
                                                                        }
                                                                    >
                                                                        <Button
                                                                            type="ghost"
                                                                            className="items-center justify-center"
                                                                            onClick={() =>
                                                                                add()
                                                                            }
                                                                            block
                                                                            icon={
                                                                                <FormOutlined />
                                                                            }
                                                                        >
                                                                            Add
                                                                            New
                                                                            description
                                                                        </Button>
                                                                    </Col>
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </Form.List>
                                                </Col>
                                                <Col span={4}>
                                                    {fields.length > 1 && (
                                                        <Tooltip
                                                            title={`Remove Curriculum ${
                                                                name + 1
                                                            }`}
                                                        >
                                                            <MinusCircleOutlined
                                                                className="mt-10 text-red-500 font-bold"
                                                                onClick={() =>
                                                                    remove(name)
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Col>
                                            </Row>
                                        )
                                    )}
                                    <Form.Item>
                                        <Col span={20}>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add New Course Curriculums
                                            </Button>
                                        </Col>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Divider />
                <p className="text-center text-2xl text-gray-700 font-semibold ">
                    FAQS
                </p>
                <Row gutter={16}>
                    <Col span={24}>
                        <label className="text-lg text-gray-600 pb-2">
                            Course FAQS :
                        </label>
                        <Form.List
                            name="faqs"
                            initialValue={[{ question: "", answer: "" }]}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(
                                        ({ key, name, ...restField }) => (
                                            <Row
                                                gutter={10}
                                                align="center"
                                                key={key}
                                            >
                                                <Col
                                                    span={20}
                                                    className="border-2 p-2 my-3"
                                                >
                                                    <Row gutter={10}>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                label={
                                                                    "Question " +
                                                                    (name + 1)
                                                                }
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    "question",
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: `Question ${
                                                                            name +
                                                                            1
                                                                        } require`,
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    placeholder={`Question ${
                                                                        name + 1
                                                                    }`}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={10}>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                label={`Question ${
                                                                    name + 1
                                                                } Answer`}
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    "answer",
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: `Question ${
                                                                            name +
                                                                            1
                                                                        } Answer require`,
                                                                    },
                                                                ]}
                                                            >
                                                                <Input.TextArea
                                                                    autoSize={{
                                                                        minRows: 3,
                                                                    }}
                                                                    placeholder={`Answer ${
                                                                        name + 1
                                                                    }`}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col span={4}>
                                                    {fields.length > 1 && (
                                                        <Tooltip
                                                            title={`Remove Answer ${
                                                                name + 1
                                                            }`}
                                                        >
                                                            <MinusCircleOutlined
                                                                className="mt-24 text-red-500 font-bold"
                                                                onClick={() =>
                                                                    remove(name)
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Col>
                                            </Row>
                                        )
                                    )}
                                    <Form.Item>
                                        <Col span={20}>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add New FAQ
                                            </Button>
                                        </Col>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="copiedId" label="copiedId" hidden>
                            <Input
                                placeholder="Please enter the copiedId"
                                hidden
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="sessionTiming"
                            label="Session Timing"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter sessionTiming",
                                },
                            ]}
                        >
                            <Input placeholder="Enter sessionTiming" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="sessionDuration"
                            label="Session Duration"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter sessionDuration",
                                },
                            ]}
                        >
                            <Input placeholder="Enter sessionDuration" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="schedule"
                            label="Session schedule"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter schedule",
                                },
                            ]}
                        >
                            <Input placeholder="Enter schedule" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="eligibility"
                            label="eligibility"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter eligibility",
                                },
                            ]}
                        >
                            <Input placeholder="Enter eligibility" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="doubtSession"
                            label="doubtSession"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter doubtSession",
                                },
                            ]}
                        >
                            <Input placeholder="Enter doubtSession" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="contentAvailibility"
                            label="contentAvailibility"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter contentAvailibility",
                                },
                            ]}
                        >
                            <Input placeholder="Enter contentAvailibility" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="certificate"
                            label="certificate"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter certificate",
                                },
                            ]}
                        >
                            <Input placeholder="Enter certificate" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="projectStructureLink"
                            label="projectStructureLink"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please enter projectStructureLink",
                                },
                            ]}
                        >
                            <Input placeholder="Enter projectStructureLink" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="programType"
                            label="programType"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter programType",
                                },
                            ]}
                        >
                            <Input placeholder="Enter programType" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="introVideo"
                            label="introVideo"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter introVideo",
                                },
                            ]}
                        >
                            <Input placeholder="Enter introVideo" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="averageRating"
                            label="averageRating"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter averageRating",
                                },
                            ]}
                        >
                            <Input type = "number" placeholder="Enter averageRating" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="totalRatings"
                            label="totalRatings"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter totalRatings",
                                },
                            ]}
                        >
                            <Input type = "number" placeholder="Enter totalRatings" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="completed"
                            label="Is Program completed?"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Program completed status required",
                                },
                            ]}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="isRegistrationOpened"
                            label="Is Program open for?"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Program  Registration open current status required",
                                },
                            ]}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
}

export default NewTrainingProgramDrawer;
