import { ironManInstance } from "./apiService";

const getAllActiveWorkshops = () => ironManInstance.get("/workshops");
const addNewWorkshop = async (data) =>
    await ironManInstance.post("/workshops", { ...data });
const updateWorkshop = async ({ id, workshopData }) =>
    await ironManInstance.patch(`/workshops/${id}`, workshopData);
const getWorkshopPaymentsByProjectId = (id) =>
    ironManInstance.get(`/payments?product=${id}`);
const getWorkshopBySlug = async (slug) =>
    await ironManInstance.get(`/workshops/slug/${slug}`);
const getWorkshopByID = async (id) =>
    await ironManInstance.get(`/workshops/${id}`);

export default {
    getAllActiveWorkshops,
    addNewWorkshop,
    updateWorkshop,
    getWorkshopPaymentsByProjectId,
    getWorkshopBySlug,
    getWorkshopByID,
};
