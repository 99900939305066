import { all, put, takeEvery } from "redux-saga/effects";
import workshopService from "../../services/workshopService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { workshopActions } from "../slices/workshop.slice";

export function* getWorkshops() {
	yield takeEvery(workshopActions.getAllWorkshop.type, function* (action) {
		try {
			yield put(workshopActions.setWorkshopLoading(true));
			const { data } = yield workshopService.getAllActiveWorkshops();
			yield put(workshopActions.saveWorkshop(data));
			yield put(workshopActions.setWorkshopLoading(false));
		} catch (error) {
			console.log(error);
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(workshopActions.setWorkshopLoading(false));
		}
	});
}
export function* addNewWorkshop() {
	yield takeEvery(workshopActions.addNewWorkshop.type, function* (action) {
		try {
			yield put(workshopActions.setFormSubmiting(true));
			yield workshopService.addNewWorkshop(action.payload);
			yield put(workshopActions.getAllWorkshop());
			yield put(workshopActions.setFormSubmiting(false));
		} catch (error) {
			console.log(error);
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
				yield put(workshopActions.setError(error.response.data.message));
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(workshopActions.setFormSubmiting(false));
		}
	});
}
export function* updateWorkshop() {
	yield takeEvery(workshopActions.updateWorkshop.type, function* (action) {
		try {
			const { id, data } = action.payload;
			yield put(workshopActions.setFormSubmiting(true));
			yield workshopService.updateWorkshop({ id, workshopData: data });
			yield put(workshopActions.getAllWorkshop());
			yield put(workshopActions.setFormSubmiting(false));
			sendToastNotification({ successMesssage: "Workshop Updated Successfully" });
		} catch (error) {
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
				yield put(workshopActions.setError(error.response.data.message));
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(workshopActions.setFormSubmiting(false));
		}
	});
}

export function* getAllPaymentsOfAWorkshop() {
	yield takeEvery(workshopActions.getProjectPayments.type, function* (action) {
		try {
			const id = action.payload;
			yield put(workshopActions.setPaymentLoading(true));
			const { data } = yield workshopService.getWorkshopPaymentsByProjectId(id);
			yield put(workshopActions.setWorkshopPayments(data));
			yield put(workshopActions.setPaymentLoading(false));
		} catch (error) {
			if (error.response) {
				sendToastNotification({ errormessage: error.response.data.message });
			} else {
				sendToastNotification({ errormessage: error.message });
			}
			yield put(workshopActions.setPaymentLoading(false));
		}
	});
}

export default function* workshopPageSaga() {
	yield all([getWorkshops(), addNewWorkshop(), updateWorkshop(), getAllPaymentsOfAWorkshop()]);
}
