import {
    Avatar,
    Button,
    Card,
    Col,
    Divider,
    Form,
    message,
    Row,
    Skeleton,
    Table,
    Tag,
} from "antd";
import { DatePicker, Space } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FundTwoTone, ExportOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx/xlsx.mjs";
import { NewpaymentActions } from "../../redux/slices/newPayments.slice";
import PaymentMissingDrawer from "./Components/PaymentMissingDrawer";

const { RangePicker } = DatePicker;
const columns = [
    {
        title: "S.No",
        dataIndex: "idx",
        key: "idx",
        // render: (pic) => <Avatar src={pic} />,
    },
    {
        title: "Details",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
            <div className="flex items-center">
                <div className="ml-3">
                    <h4 className="mb-0">{record.user.name}</h4>
                    <p className="mb-0">{record.user.email}</p>
                </div>
            </div>
        ),
    },
    {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: "discount",
        dataIndex: "discount",
        key: "discount",
    },
    {
        title: "Payment Status",
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        render: (text) => (
            <Tag color={text === "paid" ? "green" : "red"}>{text}</Tag>
        ),
    },
    {
        title: "Payment Mode",
        dataIndex: "paymentMethod",
        key: "paymentMethod",
        render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
        title: "Product Type",
        dataIndex: "productType",
        key: "productType",
        render: (text) => <Tag color="yellow">{text}</Tag>,
    },
    {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => {
            const date = new Date(text);
            return (
                <div>
                    <p className="mb-0">
                        {date.toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                        })}
                    </p>
                    <p className="mb-0">
                        {date.toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                        })}
                    </p>
                </div>
            );
        },
    },
    {
        title: "Billing Address",
        dataIndex: "BillingAddress",
        key: "BillingAddress",
        render: (text, record) => {
            return (
                <>
                    <p>
                        <b>Address 1:</b>{" "}
                        {record?.user?.billingAddress?.address1}
                    </p>
                    <p>
                        <b>Address 2: </b>
                        {record?.user?.billingAddress?.address2
                            ? record?.user?.billingAddress?.address2
                            : " Not Provided"}
                    </p>
                    <p>
                        <b>City: </b>
                        {record?.user?.billingAddress?.city}
                    </p>
                    <p>
                        <b>State: </b>
                        {record?.user?.billingAddress?.state}
                    </p>
                    <p>
                        <b>Country: </b>
                        {record?.user?.billingAddress?.country}
                    </p>
                    <p>
                        <b>zipcode: </b>
                        <Tag
                            color={
                                record?.user?.billingAddress?.zipcode
                                    ?.length === 6
                                    ? "green"
                                    : "red"
                            }
                        >
                            {record?.user?.billingAddress?.zipcode}
                        </Tag>
                    </p>
                </>
            );
        },
    },
];
const convertDataToTableDataFormat = (rows = []) => {
    return rows
        .filter((row) => row.paymentStatus === "paid")
        .map((row, index) => {
            return {
                idx: index + 1,
                ...row,
            };
        });
};
const monthwisepaymentTotal = (month, payments) => {
    return payments
        .filter((payment) => {
            const date = new Date(payment.createdAt);
            return date.getMonth() === month;
        })
        .reduce((acc, payment) => {
            return acc + payment.amount;
        }, 0);
};
const style = { height: 208, padding: "8px 0", borderRadius: "10px" };
function NewPayments() {
    const [rangeSelected, setrangeSelected] = useState(false);
    const [rangeDate, setrangeDate] = useState([]);
    const [drawerVisible, setdrawerVisible] = useState(false);
    const dispatch = useDispatch();
    const { loading, count, payments } = useSelector(
        (state) => state.newPayments
    );
    useEffect(() => {
        dispatch(NewpaymentActions.getAllPayments());
    }, []);
    const getDateInFormat = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        });
    };
    const drawerClose = () => {
        setdrawerVisible(false);
    };
    const showDrawer = () => {
        setdrawerVisible(true);
    };
    const convertDataToExcelData = (rows = []) => {
        return rows
            .filter((row) => row.paymentStatus === "paid")
            .map((row, index) => {
                return {
                    idx: index + 1,
                    name: row.user.name,
                    email: row.user.email,
                    phone: row.user.phone,
                    address1: row.user.billingAddress.address1,
                    address2: row.user.billingAddress.address2,
                    city: row.user.billingAddress.city,
                    state: row.user.billingAddress.state,
                    country: row.user.billingAddress.country,
                    district: row.user.billingAddress.district,
                    zipcode: row.user.billingAddress.zipcode,
                    amount: row.amount,
                    discount: row.discount,
                    paymentStatus: row.paymentStatus,
                    paymentMethod: row.paymentMethod,
                    productType: row.productType,
                    createdAt: row.createdAt,
                    tax: row.notes.tax,
                    // Added tax field in export data
                };
            });
    };
    const handelFormSublit = (values) => {
        console.log(values);
    };
    const exportToExcel = () => {
        /* convert state to workbook */
        const ws = XLSX.utils.json_to_sheet(convertDataToExcelData(payments));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, `Payments.xlsx`);
    };

    return (
        <>
            <Row gutter={28} style={{ margin: "15px 0px" }} justify="center">
                <Col className="gutter-col" span={6}>
                    {/* <div style={{ ...style }}>col-6</div> */}

                    <Skeleton active loading={loading.payments}>
                        <Card style={{ ...style }}>
                            <div className="flex  flex-col items-center ">
                                <div className="bg-red-200 p-4 rounded-md">
                                    <FundTwoTone
                                        className="text-red-500 "
                                        style={{
                                            fontSize: "27.5px",
                                            color: "#ff6b72",
                                        }}
                                    />
                                </div>
                                <div className="mt-3 text-center ">
                                    <h2 className="mb-0 font-weight-bold text-2xl">
                                        {count}
                                    </h2>
                                    <p className="mb-0 mt-1 text-muted text-gray-500">
                                        Payment Collected
                                    </p>
                                </div>
                            </div>
                        </Card>
                    </Skeleton>
                </Col>
            </Row>
            <Divider />
            <div className="flex justify-between">
                <div className="font-sans font-bold">All Payments</div>
                <div className="flex justify-between">
                    <Space direction="vertical" size={12}>
                        <RangePicker
                            onChange={(date, dateString) => {
                                setrangeSelected(true);
                                console.log(dateString);
                                setrangeDate(dateString);
                            }}
                        />
                    </Space>
                    <Button
                        icon={<ExportOutlined />}
                        onClick={exportToExcel}
                        className="flex items-center justify-center ml-6"
                    >
                        Export
                    </Button>
                </div>
            </div>
            {drawerVisible && (
                <PaymentMissingDrawer
                    visible={drawerVisible}
                    onDrawerClose={drawerClose}
                    //form={newMissingPaymentForm}
                    handelFormSubmit={handelFormSublit}
                />
            )}
            <Row className="mt-6">
                <Col span={24}>
                    <Card
                        title={
                            <div className="flex justify-between">
                                {/* <p>All Payments</p> */}
                            </div>
                        }
                        className="rounded-md p-0 home-latest-txn"
                    >
                        <Skeleton
                            active
                            loading={loading.payments}
                            paragraph={{ rows: 6 }}
                        >
                            <Table
                                className="rounded-md"
                                style={{
                                    overflow: "auto",
                                }}
                                loading={loading.payments}
                                columns={columns}
                                dataSource={
                                    rangeSelected === true
                                        ? convertDataToTableDataFormat(
                                              payments.filter(
                                                  (payment) =>
                                                      payment.createdAt >=
                                                          rangeDate[0] &&
                                                      payment.createdAt <=
                                                          rangeDate[1]
                                              )
                                          )
                                        : convertDataToTableDataFormat(payments)
                                }
                                pagination={{
                                    defaultPageSize: 40,
                                    showSizeChanger: true,
                                    pageSizeOptions: ["100", "200", "300"],
                                    showTotal: (total, range) =>
                                        `${range[0]}-${range[1]} of ${total} items`,
                                }}
                            />
                        </Skeleton>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default NewPayments;
