import React from "react";
import { EditOutlined, EllipsisOutlined, SettingOutlined, DeleteOutlined } from "@ant-design/icons";
import { Avatar, Card, Divider, Popconfirm, Tag } from "antd";
const { Meta } = Card;
export default function ProjectCard({ project, onEditClick, onDeleteClick }) {
    return (
        <Card
            style={{
                width: 300,
            }}
            // cover={
            //     <p className="p-4">
            //         Lorem, ipsum dolor sit amet consectetur adipisicing elit. Optio aut veritatis vitae, et fugit alias
            //         quos. Tempore repellendus tempora est!
            //     </p>
            // }
            actions={[
                <EditOutlined key="edit" onClick={() => onEditClick(project._id)} />,
                <Popconfirm
                    key={"delete"}
                    onConfirm={() => onDeleteClick(project._id)}
                    title="Are you sure,You want to delete？"
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined key="delete" />,
                </Popconfirm>,
            ]}
        >
            <Meta title={project.title} description={project.summary.slice(0, 300)} />
            <Divider />
            <div className="mt-3 flex flex-grow items-center">
                <p className="text-sm underline mr-2 pt-3">Mentors : </p>
                {project.mentors.map((mentor) => (
                    <Tag color="blue" className="text-xs">
                        {mentor.name}
                    </Tag>
                ))}
            </div>
            <div className="mt-3 flex flex-grow items-center">
                <p className="text-sm underline mr-2 ">Prereqsites : </p>
                <p color="blue" className="text-xs">
                    {project.prerequisites}
                </p>
            </div>

            <div className="mt-2">
                <p className="text-sm">Skills learned</p>
                {project.skills.split(",").map((skill) => (
                    <Tag color="gray" className="text-sm m-1">
                        {skill}
                    </Tag>
                ))}
                <p className="text-sm">{}</p>
            </div>
        </Card>
    );
}
