import { all, takeEvery, put } from "redux-saga/effects";
import paymentService from "../../services/paymentService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { paymentActions } from "../slices/payment.slice";

export function* getPayments() {
    yield takeEvery(paymentActions.getAllPayments.type, function* (action) {
        try {
            const { pageNo, size, search, search_by } = action.payload;
            yield put(paymentActions.setPaymentLoading(true));
            const { data } = yield paymentService.getAllPayments(
                pageNo,
                size,
                search,
                search_by
            );
            yield put(paymentActions.savePayments(data));
            yield put(paymentActions.setPaymentLoading(false));
        } catch (error) {
            console.log(error);
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(paymentActions.setPaymentLoading(false));
        }
    });
}

export function* addMissedPayment() {
    yield takeEvery(paymentActions.addMissedpayment.type, function* (action) {
        try {
            const data = action.payload;
            yield put(paymentActions.setPaymentSubmiting(true));
            yield paymentService.addMissingPayment(data);
            yield put(paymentActions.getAllPayments());
            yield put(paymentActions.setPaymentSubmiting(false));
            sendToastNotification({
                successMesssage: "Payment added Successfully",
            });
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(paymentActions.setPaymentSubmiting(false));
        }
    });
}

export default function* paymentPageSaga() {
    yield all([addMissedPayment(), getPayments()]);
}
