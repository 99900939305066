import { all, takeEvery, put } from "redux-saga/effects";
import couponServiceOld from "../../services/couponServiceOld";

import { sendToastNotification } from "../../utils/sendToastNotification";
import { couponOldActions } from "../slices/couponOld.slice";

export function* getAllCoupons() {
    yield takeEvery(couponOldActions.getAllCoupons.type, function* (action) {
        try {
            yield put(couponOldActions.setCouponLoading(true));
            const { data } = yield couponServiceOld.getAllTokens();
            console.log("data", data);
            yield put(couponOldActions.saveCoupons(data));
            yield put(couponOldActions.setCouponLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(couponOldActions.setCouponLoading(false));
        }
    });
}

export function* addNewCoupon() {
    yield takeEvery(couponOldActions.addNewCoupon.type, function* (action) {
        try {
            const { couponDetails, callback } = action.payload;
            yield put(couponOldActions.setCouponSubmiting(true));
            yield couponServiceOld.addnewtoken(couponDetails);
            yield put(couponOldActions.getAllCoupons());
            yield put(couponOldActions.setCouponSubmiting(false));

            sendToastNotification({
                successMesssage: "Coupon generated successfully",
            });
            callback();
        } catch (error) {
            console.log(error);
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(couponOldActions.setCouponSubmiting(false));
        }
    });
}
export function* updateCoupon() {
    yield takeEvery(couponOldActions.updateCoupon.type, function* (action) {
        try {
            const { couponId, couponDetails, callback } = action.payload;
            yield put(couponOldActions.setCouponSubmiting(true));
            yield couponServiceOld.updateCoupon(couponId, couponDetails);
            yield put(couponOldActions.getAllCoupons());
            yield put(couponOldActions.setCouponSubmiting(false));

            sendToastNotification({
                successMesssage: "Coupon updated successfully",
            });
            callback();
        } catch (error) {
            console.log(error);
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(couponOldActions.setCouponSubmiting(false));
        }
    });
}
export function* deleteCoupon() {
    yield takeEvery(couponOldActions.deleteCoupon.type, function* (action) {
        try {
            yield put(couponOldActions.setCouponSubmiting(true));
            yield couponServiceOld.deleteCoupon(action.payload);
            yield put(couponOldActions.getAllCoupons());
            yield put(couponOldActions.setCouponSubmiting(false));

            sendToastNotification({
                successMesssage: "Coupon deleted successfully",
            });
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(couponOldActions.setCouponSubmiting(false));
        }
    });
}
export default function* couponOldSaga() {
    yield all([
        getAllCoupons(),
        addNewCoupon(),
        deleteCoupon(),
        updateCoupon(),
    ]);
}
