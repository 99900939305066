
import { ironManInstance } from "./apiService";
// const login = ({ email, password }) => httpService.post("/admin/login", { email, password });

const loginStartWithOTP = (email) => {
	return ironManInstance.post("/auth/admin/login-start", { email });
}

const loginCompleteWithOTP = ({ email, otp }) =>
	ironManInstance.post("/auth/admin/login-complete", { email, otpEntered: otp });

// const googleLogin = (idToken) =>
// 	httpService.post(
// 		"/admin/login/google",
// 		{},
// 		{
// 			headers: {
// 				"x-auth-google": `Bearer ${idToken}`,
// 			},
// 		}
// 	);


const authService =  {
	loginStartWithOTP, 
	loginCompleteWithOTP,
	// googleLogin,
};

export default authService;