import jwtDecode from "jwt-decode";
// import httpService from "./httpService";
const key = "_authToken";
const isFirstTimeUserDataSubmited = "_isFirstTimeUserDataSubmited";
export const setAuthToken = (jwt) => {
	return localStorage.setItem(key, jwt);
};
export const deleteAuthToken = () => {
	return localStorage.removeItem(key);
};

export const getAuthToken = () => {
	return localStorage.getItem(key);
};
export const setisFirstTimeUserDataSubmited = (value) => {
	return localStorage.setItem(isFirstTimeUserDataSubmited, value);
};
export const deleteisFirstTimeUserDataSubmited = () => {
	return localStorage.removeItem(isFirstTimeUserDataSubmited);
};

export const getisFirstTimeUserDataSubmited = () => {
	return localStorage.getItem(isFirstTimeUserDataSubmited);
};

export const decodeCurrentUser = (token) => {
	try {
		const data = jwtDecode(token);
		return data;
	} catch (error) {
		return null;
	}
};

// export const storeValue = (key, value) => {
//    return localStorage.setItem(key, value);
// };

// export const retriveValue = (key) => {
//    return localStorage.getItem(key);
// };

// export const objToString = (obj) => {
//    return JSON.stringify(obj);
// };
// export const stringToObj = (str) => {
//    return JSON.parse(str);
// };

// httpService.setJwt(getItem());
