/* eslint-disable require-yield */
import { all, call, takeEvery, takeLatest, put } from "redux-saga/effects";
import authService from "../../services/authService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { authActions } from "../slices/auth.slice";


function* sendOtpSaga(action) {
	try {
		yield call(authService.loginStartWithOTP, action.payload);
		yield put(authActions.sendOtpSuccess());
		sendToastNotification({ successMessage: "OTP sent successfully" });
	} catch (error) {
		yield put(authActions.sendOtpFailure(error.message));
		sendToastNotification({ errorMessage: error.message });
	}
}

function* verifyOtpSaga(action) {
	try {
	  const { data } = yield call(authService.loginCompleteWithOTP, action.payload);
	  if (data.token && data.admin) {
	  	yield put(authActions.loginUser(data.token));
	  } else {
		yield put(authActions.verifyOtpFailure(data.error.message));
		sendToastNotification({ errorMessage: data.error.message });
	  }
	} catch (e) {
	  yield put(authActions.verifyOtpFailure(e.message));
	  sendToastNotification({ errorMessage: e.message });
	}
  }


  export default function* authSaga() {
	yield takeLatest(authActions.sendOtpRequest.type, sendOtpSaga);
	yield takeLatest(authActions.verifyOtpRequest.type, verifyOtpSaga);
}

// export function* loginUserUsingEmailOTP() {
// 	yield takeEvery(authActions.loginUserWithEmailOTP.type, function* (action) {
// 		try {
// 			console.log(action.payload);
// 			console.log("inauthactionpayload", action.payload);
// 			const email = action.payload;
// 			console.log("inauthaction", email);
// 			const response = yield authService.loginStartWithOTP(email);
// 			if (response.success) {
//                 // If the login is successful, dispatch a success action with necessary payload.
//                 yield put(authActions.loginSuccess(response.data));
//                 // sendToastNotification({ successMessage: "Login successful" });
//             } else {
//                 // If the response indicates failure, dispatch an error action here.
//                 yield put(authActions.loginFailure(response.message));
//                 sendToastNotification({ errormessage: response.message });
//             }
// 			// sendToastNotification({ successMessage: "Loge" });
// 		} catch (error) {
// 			if (error.response) {
// 				sendToastNotification({ errormessage: error.response.data.message });
// 			} else {
// 				sendToastNotification({ errormessage: error.message });
// 			}
// 		}
// 	});
// }

// export function* otpSendingFailed() {
// 	yield takeEvery(authActions.otpSendingFailed.type, function* (action) {
// 		try {
// 			console.log(action.payload);
// 			const { error } = action.payload;
// 			sendToastNotification({ errormessage: error.message });
// 		} catch (error) {
// 			if (error.response) {
// 				sendToastNotification({ errormessage: error.response.data.message });
// 			} else {
// 				sendToastNotification({ errormessage: error.message });
// 			}
// 		}
// 	});

// }
// export function* verifyUserUsingEmailOTP() {
// 	yield takeEvery(authActions.verifyUserWithEmailOTP.type, function* (action) {
// 		try {
// 			console.log(action.payload);
// 			const { email, otp } = action.payload;
// 			const { data } = yield authService.loginCompleteWithOTP({ email, otp });
// 			// sendToastNotification({ successMesssage: "Loge" });
// 			if (data.token && data.admin) {
// 				yield put(authActions.loginUser(data.token));
// 			} else {
// 				yield put(authActions.loginUser(null));
// 			}
// 		} catch (error) {
// 			if (error.response) {
// 				sendToastNotification({ errormessage: error.response.data.message });
// 			} else {
// 				sendToastNotification({ errormessage: error.message });
// 			}
// 		}
// 	});
// }
// export function* loginUserUsingGoogle() {
// 	yield takeEvery(authActions.loginUserWithGoogle.type, function* (action) {
// 		try {
// 			const idToken = action.payload;
// 			const { data } = yield authService.googleLogin(idToken);
// 			// sendToastNotification({ successMesssage: "Loge" });
// 			if (data.token && data.admin) {
// 				yield put(authActions.loginUser(data.token));
// 			} else {
// 				yield put(authActions.loginUser(null));
// 				sendToastNotification({ errormessage: "Only Admin can login" });
// 			}
// 		} catch (error) {
// 			if (error.response) {
// 				sendToastNotification({ errormessage: error.response.data.message });
// 			} else {
// 				sendToastNotification({ errormessage: error.message });
// 			}
// 		}
// 	});
// }

// export default function* authSaga() {
// 	yield all([loginUserUsingEmailOTP(), verifyUserUsingEmailOTP(), otpSendingFailed(), loginUserUsingGoogle()]);
// }
