import { ironManInstance } from "../services/apiService";

export const uploadFile = async (file, dir, onProgress) => {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
        onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            onProgress({ percent });
        },
    };
    try {
        const result = await ironManInstance.post(`/file/upload?dir=${dir}`, formData, config);
        return result.data;
    } catch (error) {
        console.log(error);
        return false;
    }
};
