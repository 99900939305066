import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    payments: [],
    count: 0,
    loading: {
        payments: false,
        submiting: false,
    },
};

const NewpaymentSlice = createSlice({
    name: "Newpayment",
    initialState,
    reducers: {
        getAllPayments() {},
        addMissedpayment() {},
        savePayments(state, action) {
            const { payments, count } = action.payload;
            state.payments = payments;
            state.count = count;
        },
        setPaymentLoading(state, action) {
            state.loading.payments = action.payload;
        },
        setPaymentSubmiting(state, action) {
            state.loading.submiting = action.payload;
        },
    },
});
export const NewpaymentReducer = NewpaymentSlice.reducer;
export const NewpaymentActions = NewpaymentSlice.actions;
