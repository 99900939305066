import { Button, Card, Col, Row, Skeleton, Table } from "antd";
import {
    UserOutlined,
    FundTwoTone,
    ExportOutlined,
    DollarOutlined,
} from "@ant-design/icons";
import { DatePicker, Space } from "antd";
import { useState } from "react";
const { RangePicker } = DatePicker;
const convertDataToTableDataFormat = (rows = []) => {
    return rows.map((row, index) => {
        return {
            idx: index + 1,
            ...row,
        };
    });
};
function Payments({ loading, payments, exportToExcel, columns }) {
    console.log(payments);
    const [rangeSelected, setrangeSelected] = useState(false);
    const [rangeDate, setrangeDate] = useState([]);
    return (
        <Row>
            <Col span={24}>
                <Card
                    title={
                        <>
                            <div className="flex justify-between">
                                <p>All Payments</p>

                                <Button
                                    icon={<ExportOutlined />}
                                    onClick={exportToExcel}
                                    className="flex items-center justify-center"
                                >
                                    Export
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "10px",
                                }}
                            >
                                <Space direction="vertical" size={12}>
                                    <RangePicker
                                        onChange={(date, dateString) => {
                                            setrangeDate(dateString);
                                            setrangeSelected(true);
                                        }}
                                    />
                                </Space>
                            </div>
                        </>
                    }
                    className="rounded-md p-0 home-latest-txn"
                >
                    <Skeleton
                        active
                        loading={loading.payments}
                        paragraph={{ rows: 6 }}
                    >
                        <Table
                            style={{
                                overflowX: "auto",
                            }}
                            pagination={{
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ["10", "20", "30"],
                            }}
                            columns={columns}
                            dataSource={
                                rangeSelected
                                    ? convertDataToTableDataFormat(
                                          payments.filter((payment) => {
                                              return (
                                                  payment.createdAt >=
                                                      rangeDate[0] &&
                                                  payment.createdAt <=
                                                      rangeDate[1]
                                              );
                                          })
                                      )
                                    : convertDataToTableDataFormat(payments)
                            }
                        />
                    </Skeleton>
                </Card>
            </Col>
        </Row>
    );
}

export default Payments;
