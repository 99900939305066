import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Row,
    Space,
    Select,
    Tag,
} from "antd";
import React from "react";

export default function AccessViewerDrawer({
    visible,
    open,
    onClose,
    handelFormSubmit,
    accessProjectForm,
    curraccess,
    childrenroles,
    accessList,
}) {
    const getArrayValueForAccess = (current) => {
        const array = [];
        for (var i = 0; i < current.length; i++) {
            array.push(current[i].access);
        }
        return array;
    };
    // console.log(curraccess);
    return (
        <>
            {visible === true ? (
                <>
                    <Drawer
                        title="Edit Access"
                        placement="right"
                        width={500}
                        onClose={onClose}
                        visible={visible}
                        extra={
                            <Space>
                                <Button onClick={onClose}>Cancel</Button>
                            </Space>
                        }
                    >
                        <Form
                            name="access-viewer-form"
                            onFinish={handelFormSubmit}
                            form={accessProjectForm}
                            layout="vertical"
                            scrollToFirstError={true}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="name" label="Name">
                                        <Input
                                            placeholder={curraccess.user.name}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="phoneno" label="Phone No.">
                                        <Input
                                            placeholder={curraccess.user.phone}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="email" label="Email">
                                        <Input
                                            placeholder={curraccess.user.email}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="roles" label="Roles">
                                        <Select
                                            style={{
                                                width: "100%",
                                                paddingTop: "20px",
                                            }}
                                            placeholder="Edit roles"
                                            defaultValue={curraccess.role}
                                        >
                                            {childrenroles?.map(
                                                (role, index) => {
                                                    return (
                                                        <>
                                                            <Tag
                                                                key={index}
                                                                value={role}
                                                                color={
                                                                    "volcano"
                                                                }
                                                            >
                                                                {role}
                                                            </Tag>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item name="access" label="Access">
                                        <Select
                                            mode="tags"
                                            style={{
                                                width: "100%",
                                                paddingTop: "20px",
                                            }}
                                            placeholder="access "
                                            defaultValue={getArrayValueForAccess(
                                                curraccess.accesses
                                            )}
                                        >
                                            {accessList?.map(
                                                (access, index) => {
                                                    return (
                                                        <>
                                                            <Tag
                                                                key={index}
                                                                value={access}
                                                                color={
                                                                    "volcano"
                                                                }
                                                            >
                                                                {access}
                                                            </Tag>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                wrapperCol={{
                                    offset: 8,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Drawer>
                </>
            ) : (
                <></>
            )}
        </>
    );
}
