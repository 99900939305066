import React from "react";
import {
    Card,
    Col,
    Divider,
    Row,
    Skeleton,
    Table,
    Tag,
    Button,
    Avatar,
    Tabs,
} from "antd";
import {
    UserOutlined,
    FundTwoTone,
    ExportOutlined,
    DollarOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/formatDate";
import NewWorkshopService from "../../services/NewWorkshopService";
import newTrainingProgramService from "../../services/newTrainingProgramService";
import { newTrainingProgramActions } from "../../redux/slices/newTrainingProgram.slice";
// import Payments from "./Components/Payments";
import TabPane from "rc-tabs/lib/TabPanelList/TabPane";
import Settings from "./Component/Settings";
import Payments from "./Component/Payments";
import RegisteredUsers from "./Component/RegisteredUsers";

// import Settings from "./Components/Settings";
const style = { height: 208, padding: "8px 0", borderRadius: "10px" };

function numberWithCommas(number) {
    let nf = new Intl.NumberFormat("en-US");
    return nf.format(number);
    // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const columns = [
    {
        title: "S.No",
        dataIndex: "idx",
        key: "idx",
        // render: (pic) => <Avatar src={pic} />,
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => <p>{record.user.name}</p>,
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (text, record) => <p>{record.user.email}</p>,
    },
    {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        render: (text, record) => <p>{record.user.phone}</p>,
    },
    {
        title: "Product",
        dataIndex: "paidFor",
        key: "paidFor",
        render: (text, record) => <p>{record.productType}</p>,
    },
    {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (amount) => <p>₹{amount}</p>,
    },
    {
        title: "Discount",
        dataIndex: "discountAmount",
        key: "discountAmount",
        render: (text, record) => <p>₹{record.discount}</p>,
    },
    {
        title: "Tax",
        dataIndex: "tax",
        key: "tax",
        render: (text, record) => (
            <p>{Math.round(record?.notes?.tax * 100) / 100}</p>
        ),
    },
    {
        title: "Coupon",
        dataIndex: "coupon",
        key: "coupon",
        render: (coupon) => (
            <>
                {coupon ? (
                    <Tag color="blue" className=" rounded-lg">
                        {coupon}
                    </Tag>
                ) : (
                    <Tag color="green" className="rounded-lg">
                        NO COUPON
                    </Tag>
                )}
            </>
        ),
    },
    {
        title: "Status",
        key: "paymentStatus",
        dataIndex: "paymentStatus",
        render: (paymentStatus) => (
            <>
                <Tag color="green" className="rounded-lg">
                    {paymentStatus} 💰
                </Tag>
            </>
        ),
    },
    {
        title: "createdAt",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt) => <p>{formatDate(createdAt)}</p>,
    },
];
const calculateTotalAmountOFPayments = (payments) => {
    let totalAmount = 0;
    payments.forEach((payment) => {
        totalAmount += payment.amount;
    });
    return totalAmount;
};
const getFoundTrainingProgram = (newTrainingProgram, id) => {
    const active = newTrainingProgram?.active?.filter(
        (program) => program._id === id
    )[0];
    const completed = newTrainingProgram?.completed?.filter(
        (program) => program._id === id
    )[0];
    return active || completed;
};
function NewTrainingProgramDetails() {
    const [amountLoading, setAmountLoading] = React.useState(true);

    const { id } = useParams();
    const dispatch = useDispatch();
    const { newTrainingProgram } = useSelector(
        (state) => state.newTrainingProgram
    );
    const FoundTrainingProgram = getFoundTrainingProgram(
        newTrainingProgram,
        id
    );
    const {
        newTrainingProgramPayment: { payments, paymentCount, total },
        loading,
    } = useSelector((state) => state.newTrainingProgram);
    useEffect(() => {
        dispatch(newTrainingProgramActions.getAllNewTrainingPrograms());
        if (id) {
            dispatch(
                newTrainingProgramActions.getPaymentDetailsOfNewTrainingProgram(
                    id
                )
            );
        }
    }, []);
    const removeFailedPayments = (payments) => {
        return payments.filter((payment) => payment.paymentStatus !== "failed");
    };
    const convertDataToExpartFormat = (rows = []) => {
        return rows.map((row, index) => {
            return {
                SL_NO: index + 1,
                Payment_ID: row._id,
                name: row.user.name,
                email: row.user.email,
                Mobile_Number: row.user.phone,
                paidFor: row.productType,
                amount: row.amount,
                discountAmount: row.discount,
                coupon: row.coupon,
                status: row.paymentStatus,
                // project:
                //     row.paidFor === "Workshop"
                //         ? row.workshop.title
                //         : row.projectId.title,
                receptId: row.receiptId,
                order_id: row.orderId,
                payment_id: row.paymentId,
                address1: row.user.billingAddress.address1,
                address2: row.user.billingAddress.address2,
                city: row.user.billingAddress.city,
                state: row.user.billingAddress.state,
                country: row.user.billingAddress.country,
                district: row.user.billingAddress.district,
                zipcode: row.user.billingAddress.zipcode,
                date: new Date(row.createdAt).toLocaleString("en-GB", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                }),
            };
        });
    };
    const exportToExcel = () => {
        /* convert state to workbook */
        const ws = XLSX.utils.json_to_sheet(
            convertDataToExpartFormat(payments)
        );
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, `workshop_${FoundTrainingProgram.batchId}.xlsx`);
    };
    const getRegisteredUsersCount = (payments) => {
        let users = [];
        payments
            .filter((payment) => {
                return payment.paymentStatus === "paid";
            })
            .forEach((payment) => {
                users.push(payment.user);
            });
        console.log("users", users);
        return users.length;
    };
    const getUsersDataFromPayments = (payments) => {
        let users = [];
        payments?.forEach((payment) => {
            users.push(payment.user);
        });
        return users;
    };

    return (
        <div>
            <Row gutter={28} style={{ margin: "15px 0px" }} justify="center">
                <Col className="gutter-row" span={6}>
                    {/* <div style={{ ...style }}>col-6</div> */}
                    <Skeleton active loading={loading.payments}>
                        <Card style={{ ...style }}>
                            <div className="flex  flex-col items-center ">
                                <div className="bg-red-200 p-4 rounded-md">
                                    <UserOutlined
                                        className="text-red-500 "
                                        style={{
                                            fontSize: "27.5px",
                                            color: "#ff6b72",
                                        }}
                                    />
                                </div>
                                <div className="mt-3 text-center ">
                                    <h2 className="mb-0 font-weight-bold text-2xl">
                                        {removeFailedPayments(payments).length}
                                    </h2>
                                    <p className="mb-0 mt-1 text-muted text-gray-500">
                                        Enrolled Users
                                    </p>
                                </div>
                            </div>
                        </Card>
                    </Skeleton>
                </Col>
                <Col className="gutter-row" span={6}>
                    {/* <div style={{ ...style }}>col-6</div> */}
                    <Skeleton active loading={loading.payments}>
                        <Card style={{ ...style }}>
                            <div className="flex  flex-col items-center ">
                                <div className="bg-green-200 p-4 rounded-md">
                                    <FundTwoTone
                                        className="text-green-500 "
                                        style={{
                                            fontSize: "27.5px",
                                            color: "#ff6b72",
                                        }}
                                    />
                                </div>
                                <div className="mt-3 text-center ">
                                    <h2 className="mb-0 font-weight-bold text-2xl">
                                        {payments?.length}
                                    </h2>
                                    <p className="mb-0 mt-1 text-muted text-gray-500">
                                        Total No of Payments Done
                                    </p>
                                    <p>
                                        <Tag color="green">
                                            Success :{" "}
                                            {
                                                removeFailedPayments(payments)
                                                    ?.length
                                            }
                                        </Tag>
                                        <Tag color="red">
                                            Failed :{" "}
                                            {payments?.length -
                                                removeFailedPayments(payments)
                                                    ?.length}
                                        </Tag>
                                    </p>
                                </div>
                            </div>
                        </Card>
                    </Skeleton>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Skeleton active loading={loading.payments}>
                        <Card style={{ ...style }}>
                            <div className="flex  flex-col items-center ">
                                <div className="bg-blue-200 p-4 rounded-md">
                                    <DollarOutlined
                                        className="text-blue-400 "
                                        style={{
                                            fontSize: "27.5px",
                                            color: "#ff6b72",
                                        }}
                                    />
                                </div>
                                <div className="mt-3 text-center ">
                                    <h2 className="mb-0 font-weight-bold text-2xl">
                                        ₹ {numberWithCommas(total)}
                                    </h2>
                                    <p className="mb-0 mt-1 text-muted text-gray-500">
                                        Total Payment Collected
                                    </p>
                                </div>
                            </div>
                        </Card>
                    </Skeleton>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={24}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Payments" key="1">
                            <Payments
                                payments={removeFailedPayments(payments)}
                                loading={loading.payments}
                                exportToExcel={exportToExcel}
                                columns={columns}
                            />
                        </TabPane>
                        <TabPane tab="Registered Users" key="2">
                            {/* <RegisteredUsers
                                users={getUsersDataFromPayments(
                                    removeFailedPayments(payments)
                                )}
                                id={id}
                            /> */}
                            <RegisteredUsers
                                users={getUsersDataFromPayments(
                                    removeFailedPayments(payments)
                                )}
                                id={id}
                            />
                        </TabPane>
                        <TabPane tab="Settings" key="3">
                            <Settings
                                FoundTrainingProgram={FoundTrainingProgram}
                            />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
    );
}

export default NewTrainingProgramDetails;
