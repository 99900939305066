import createSagaMiddleware from "@redux-saga/core";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootSaga from "../sagas";
import { accessReducer } from "../slices/accessviewer.slice";
import { userReducer } from "../slices/user.slice";
import { authReducer } from "../slices/auth.slice";
import { dashboardPageReducer } from "../slices/dashboard.slice";
import { projectReducer } from "../slices/project.slice";
import { workshopReducer } from "../slices/workshop.slice";
import { paymentReducer } from "../slices/payment.slice";
import { instructorReducer } from "../slices/instructor.slice";
import { resourceReducer } from "../slices/resources.slice";
import { couponReducer } from "../slices/coupon.slice";
import { jobsReducer } from "../slices/jobs.slice";
import { newsletterReducer } from "../slices/newsletter.slice";
import { blogReducer } from "../slices/blog.slice";
import { internshipProjectReducer } from "../slices/internshipProject";
import { NewWorkShopReducer } from "../slices/NewWorkshop.slice.js";
import { couponOldReducer } from "../slices/couponOld.slice";
import { productRegistrationsReducer } from "../slices/productRegistrations.slice";
import { NewpaymentReducer } from "../slices/newPayments.slice";
import { newTrainingProgramReducer } from "../slices/newTrainingProgram.slice";
import { udemyCoursesReducer } from "../slices/udemyCourses.slice.js"; 

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
    reducer: {
        user: userReducer,
        auth: authReducer,
        dashboardPage: dashboardPageReducer,
        project: projectReducer,
        workshop: workshopReducer,
        payment: paymentReducer,
        instructor: instructorReducer,
        resource: resourceReducer,
        coupon: couponReducer,
        job: jobsReducer,
        newsletter: newsletterReducer,
        blog: blogReducer,
        internshipProject: internshipProjectReducer,
        access: accessReducer,
        NewWorkshop: NewWorkShopReducer,
        couponOld: couponOldReducer,
        productRegistrations: productRegistrationsReducer,
        newPayments: NewpaymentReducer,
        newTrainingProgram: newTrainingProgramReducer,
        udemyCourses: udemyCoursesReducer
    },
    middleware: [
        ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
        sagaMiddleware,
    ],
});
sagaMiddleware.run(rootSaga);
export default store;
