import React, { useEffect } from "react";
import {
    Card,
    Col,
    Layout,
    Menu,
    Row,
    Divider,
    List,
    Avatar,
    Table,
    Tag,
} from "antd";
import {
    UserOutlined,
    FundTwoTone,
    ReadFilled,
    ToolOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { dashboardPageActions } from "../../redux/slices/dashboard.slice";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import { formatDate } from "../../utils/formatDate";
import { accessActions } from "../../redux/slices/accessviewer.slice";
const columns = [
    {
        title: "Avatar",
        dataIndex: "pic",
        key: "pic",
        render: (pic) => <Avatar src={pic} />,
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <a>{text}</a>,
    },
    {
        title: "Product",
        dataIndex: "paidFor",
        key: "paidFor",
        render: (text) => (
            <span>
                {text === "Workshop" ? (
                    <Tag color="warning" className=" rounded-lg">
                        {text}
                    </Tag>
                ) : (
                    <Tag color="green" className="rounded-lg">
                        Project
                    </Tag>
                )}
            </span>
        ),
    },
    {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (amount) => <p>₹{amount}</p>,
    },
    {
        title: "Product Name",
        dataIndex: "project",
        key: "project",
    },
    {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (status) => (
            <span>
                {status === "Succcessful payment" ? (
                    <Tag color="blue" className=" rounded-lg">
                        SUCCESS
                    </Tag>
                ) : (
                    <Tag color="error" className="rounded-lg">
                        PENDING OR FAILED
                    </Tag>
                )}
            </span>
        ),
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (date) => <p>{formatDate(date || new Date())}</p>,
    },
];

const style = { height: 208, padding: "8px 0", borderRadius: "10px" };
function numberWithCommas(number) {
    let nf = new Intl.NumberFormat("en-US");
    return nf.format(number);
    // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function Dashboard(props) {
    const dispatch = useDispatch();
    const { dashboardPageLoading, dashboardPageData } = useSelector(
        (state) => state.dashboardPage
    );

    useEffect(() => {
        dispatch(dashboardPageActions.getDashboardpageData());
    }, []);

    const convertDataToTableDataFormat = (rows = []) => {
        return rows.map((row) => {
            return {
                key: row._id,
                pic: row.userId.personal.img,
                name:
                    row.userId.personal.firstname +
                    " " +
                    row.userId.personal.lastname,
                paidFor: row.paidFor ? row.paidFor : "Project",
                amount: row.amount,
                status: row.message,
                project:
                    row.paidFor === "Workshop"
                        ? row.workshop.title
                        : row.projectId.title,
                date: new Date(row.createdAt),
            };
        });
    };
    return (
        <div>
            {dashboardPageLoading ? (
                <Loader />
            ) : (
                <>
                    <Row gutter={28} style={{ margin: "15px 0px" }}>
                        <Col className="gutter-row" span={6}>
                            {/* <div style={{ ...style }}>col-6</div> */}
                            <Card style={{ ...style }}>
                                <div className="flex  flex-col items-center ">
                                    <div className="bg-red-200 p-4 rounded-md">
                                        <UserOutlined
                                            className="text-red-500 "
                                            style={{
                                                fontSize: "27.5px",
                                                color: "#ff6b72",
                                            }}
                                        />
                                    </div>
                                    <div className="mt-3 text-center ">
                                        <h2 className="mb-0 font-weight-bold text-2xl">
                                            {dashboardPageData?.noOfUsers}
                                        </h2>
                                        <p className="mb-0 mt-1 text-muted text-gray-500">
                                            Active user
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <Card style={{ ...style }}>
                                <div className="flex  flex-col items-center ">
                                    <div className="bg-green-200 p-4 rounded-md">
                                        <ReadFilled
                                            className="text-green-500"
                                            style={{
                                                fontSize: "27.5px",
                                                color: "#04d182",
                                            }}
                                        />
                                    </div>
                                    <div className="mt-3 text-center ">
                                        <h2 className="mb-0 font-weight-bold text-2xl">
                                            {dashboardPageData?.projectCount}
                                        </h2>
                                        <p className="mb-0 mt-1 text-muted text-gray-500">
                                            Running Projects
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <Card style={{ ...style }}>
                                <div className="flex  flex-col items-center ">
                                    <div className="bg-blue-200 p-4 rounded-md">
                                        <FundTwoTone
                                            className="text-blue-400 "
                                            style={{
                                                fontSize: "27.5px",
                                                color: "#ff6b72",
                                            }}
                                        />
                                    </div>
                                    <div className="mt-3 text-center ">
                                        <h2 className="mb-0 font-weight-bold text-2xl">
                                            ₹{" "}
                                            {numberWithCommas(
                                                dashboardPageData?.totalPaymentCollected
                                            )}
                                        </h2>
                                        <p className="mb-0 mt-1 text-muted text-gray-500">
                                            Total Payment
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col className="gutter-row" span={6}>
                            <Card style={{ ...style }}>
                                <div className="flex  flex-col items-center ">
                                    <div className="bg-pink-200 p-4 rounded-md">
                                        <ToolOutlined
                                            className="text-pink-400 "
                                            style={{
                                                fontSize: "27.5px",
                                                color: "#ff6b72",
                                            }}
                                        />
                                    </div>
                                    <div className="mt-3 text-center ">
                                        <h2 className="mb-0 font-weight-bold text-2xl">
                                            {dashboardPageData?.activeWorkshops}
                                        </h2>
                                        <p className="mb-0 mt-1 text-muted text-gray-500">
                                            Active Workshops
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={24}>
                        <Col span={8}>
                            <Card
                                className="rounded-lg"
                                title="New Join Member"
                            >
                                <List
                                    itemLayout="horizontal"
                                    dataSource={
                                        dashboardPageData
                                            ? dashboardPageData.topTenRecentUsers
                                            : []
                                    }
                                    renderItem={(item) => (
                                        <List.Item className="items-center">
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        className="h-8 w-8"
                                                        src={item.personal.img}
                                                    />
                                                }
                                                title={
                                                    <span className="flex justify-between p-0 m-0">
                                                        <p className="text-gray-500 text-lg font-semibold">
                                                            {item.personal
                                                                .firstname +
                                                                item.personal
                                                                    .lastname}
                                                        </p>
                                                        <p className="text-gray-600 font-semibold">
                                                            {formatDate(
                                                                item.createdAt
                                                            )}
                                                        </p>
                                                    </span>
                                                }
                                                description={
                                                    <div>
                                                        {item.personal
                                                            ?.phone && (
                                                            <p className="">
                                                                {
                                                                    item
                                                                        .personal
                                                                        .phone
                                                                }
                                                            </p>
                                                        )}
                                                        <p>
                                                            {
                                                                item.personal
                                                                    .email
                                                            }
                                                        </p>
                                                    </div>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                                ,
                            </Card>
                        </Col>
                        <Col span={16}>
                            <Card
                                title="Latest Transactions"
                                className="rounded-md p-0 home-latest-txn"
                            >
                                <Table
                                    style={{
                                        overflow: "auto",
                                    }}
                                    pagination={false}
                                    columns={columns}
                                    dataSource={convertDataToTableDataFormat(
                                        dashboardPageData
                                            ? dashboardPageData.topTenRecentPayments
                                            : []
                                    )}
                                />
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
}

export default Dashboard;
