import { all, takeEvery, put } from "redux-saga/effects";
import blogService from "../../services/blogService";
import { sendToastNotification } from "../../utils/sendToastNotification";
import { blogActions } from "../slices/blog.slice";

export function* AddNewBlog() {
    yield takeEvery(blogActions.addBlog.type, function* (action) {
        try {
            yield put(blogActions.setUpdating(true));
            const { data } = yield blogService.addNewBlog(action.payload);
            // yield put(
            //     jobsActions.getAllJobs({
            //         pageNo: 1,
            //         size: 20,
            //     })
            // )

            yield put(blogActions.setUpdating(false));
            window.location.href = `/blogs/edit/${data._id}`;
            sendToastNotification({
                successMesssage: "Blog Added Successfully",
            });
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(blogActions.setUpdating(false));
        }
    });
}
const saveNewBlogAndPublish = function* (action) {
    yield takeEvery(blogActions.addBlog.type, function* (action) {
        try {
            yield put(blogActions.setUpdating(true));
            const { data } = yield blogService.addNewBlog(action.payload);
            // yield put(
            //     jobsActions.getAllJobs({
            //         pageNo: 1,
            //         size: 20,
            //     })
            // );
            yield blogService.publishBlog(data._id);

            yield put(blogActions.setUpdating(false));
            sendToastNotification({
                successMesssage: "Blog Published Successfully",
            });
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(blogActions.setUpdating(false));
        }
    });
};
export function* getAllBlogs() {
    yield takeEvery(blogActions.getBlogs.type, function* (action) {
        try {
            yield put(blogActions.setBlogsLoading(true));
            const { pageNo, size } = action.payload;
            const { data } = yield blogService.getAllBlogs(pageNo, size);
            yield put(blogActions.saveBlogs(data));
            yield put(blogActions.setBlogsLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(blogActions.setBlogsLoading(false));
        }
    });
}
export function* getMyBlogs() {
    yield takeEvery(blogActions.getMyBlogs.type, function* (action) {
        try {
            yield put(blogActions.setMyBlogLoading(true));

            const { data } = yield blogService.getMyBlogs();

            yield put(blogActions.saveMyBlogs(data));

            yield put(blogActions.setMyBlogLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(blogActions.setMyBlogLoading(false));
        }
    });
}
export function* getUnAprovedBlogs() {
    yield takeEvery(blogActions.getUnAprovedBlogs.type, function* (action) {
        try {
            yield put(blogActions.setBlogsLoading(true));

            const { data } = yield blogService.getPendingBlogs();

            yield put(blogActions.saveUnapprovedBlogs(data));

            yield put(blogActions.setBlogsLoading(false));
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(blogActions.setBlogsLoading(false));
        }
    });
}

export function* approveBlog() {
    yield takeEvery(blogActions.approveBlog.type, function* (action) {
        try {
            yield put(blogActions.setBlogsLoading(true));
            yield blogService.approveBlog(action.payload);

            yield put(blogActions.getUnAprovedBlogs());
            yield put(blogActions.setBlogsLoading(false));
            sendToastNotification({ successMesssage: "Done!" });
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(blogActions.setBlogsLoading(false));
        }
    });
}
export function* deleteBlog() {
    yield takeEvery(blogActions.deleteBlog.type, function* (action) {
        try {
            const { blogId, callback } = action.payload;
            yield put(blogActions.setBlogsLoading(true));
            yield blogService.deleteBlog(blogId);
            yield put(blogActions.setBlogsLoading(false));
            callback();
            sendToastNotification({
                successMesssage: "Blog deleted Successfully",
            });
        } catch (error) {
            if (error.response) {
                sendToastNotification({
                    errormessage: error.response.data.message,
                });
            } else {
                sendToastNotification({ errormessage: error.message });
            }
            yield put(blogActions.setBlogsLoading(false));
        }
    });
}

export default function* blogSaga() {
    yield all([
        AddNewBlog(),
        getMyBlogs(),
        getUnAprovedBlogs(),
        approveBlog(),
        deleteBlog(),
        getAllBlogs(),
    ]);
}
