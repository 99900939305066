import {
    Button,
    Checkbox,
    Col,
    Divider,
    Drawer,
    Form,
    Input,
    Row,
    Select,
    Space,
} from "antd";
import React, { useState } from "react";
import TinymceEditor from "../../Components/TinyMceEditor/TinymceEditor";
import FechUserList from "./Components/FechUserList";
import ImageUploadInput from "../../Components/Shared/ImageUploadInput";
function getformated(date) {
    var formatedDate = "";
    var yyyy = date.slice(0, 4);
    var mm = date.slice(5, 7);
    var dd = date.slice(8, 10);
    formatedDate = yyyy + "-" + mm + "-" + dd;
    return formatedDate;
}
function EditInternshipProject({
    handelFormSubmit,
    visible,
    onClose,
    data,
    onStatusChange,
}) {
    console.log(data);
    const [publishedClicked, setPublishedClicked] = useState(false);
    const [internshipProjectForm] = Form.useForm();
    if (data) {
        const objDataTOForm = {
            title: data.title,
            description: data.description,
            mentors: data.mentors.map((mentor) => ({
                label: mentor.name,
                value: mentor._id,
            })),
            collaborators: data.collaborators.map((collaborator) => ({
                label: collaborator.name,
                value: collaborator._id,
            })),
            skills: data.skills,
            summary: data.summary,
            prerequisites: data.prerequisites,
            image: data.image || "",
            introVideo: data.introVideo || "",
            duration: data.duration,
            difficulty: data.difficulty,
            completedOn: getformated(data.completedOn.slice(0, 10)),
        };
        internshipProjectForm.setFieldsValue(objDataTOForm);
    }

    return (
        <>
            <Drawer
                title={`${!data ? "New Project" : "Edit Project"}`}
                width={800}
                onClose={onClose}
                visible={visible}
                // bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <Space className="flex justify-end">
                        <Button onClick={onClose}>Cancel</Button>
                        <Button
                            form="internship-project-form"
                            type="submit"
                            htmlType="form"
                        >
                            Submit
                        </Button>
                    </Space>
                }
            >
                <div className="flex flex-col w-full px-10">
                    <Form
                        name="internship-project-form"
                        onFinish={handelFormSubmit}
                        form={internshipProjectForm}
                        layout="vertical"
                        scrollToFirstError={true}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="title"
                                    label="Project Title"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter project title",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Eg: Rectifying Leakages in Rocket Propulsion" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="summary"
                                    label="Short description about the project"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please write a short description",
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        type="text"
                                        placeholder="Eg: Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat, voluptatibus!"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label="Project Description"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Project description is required",
                                        },
                                    ]}
                                >
                                    <TinymceEditor
                                        initialValue={internshipProjectForm.getFieldValue(
                                            "description"
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="image"
                                    label="Share Project image"
                                    rules={[{ required: false }]}
                                >
                                    <ImageUploadInput />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="introVideo"
                                    label="Share a intro video about the project"
                                    rules={[{ required: false }]}
                                >
                                    <Input
                                        type="url"
                                        placeholder="video link"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="completedOn"
                                    label="Project Completed On"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Project complexity required",
                                        },
                                    ]}
                                >
                                    <Input type="date" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="duration"
                                    label="Project Duration"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Duration required",
                                        },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        placeholder="Eg:8 weeks"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="prerequisites"
                                    label="Project Pre-requisites"
                                    rules={[
                                        { required: true, message: "required" },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        placeholder="eg: Python,Nodejs,AI,ML"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="skills"
                                    label="Project Skills"
                                    rules={[
                                        { required: true, message: "required" },
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        placeholder="eg: AI,ML"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* ['easy', 'medium', 'hard', 'advanced'] */}
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="difficulty"
                                    label="Project Dificulty"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "project dificulty is required",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Difficulty"
                                        className="bg-blue-500"
                                    >
                                        <Select.Option
                                            key={"easy"}
                                            value="easy"
                                        >
                                            Easy
                                        </Select.Option>
                                        <Select.Option
                                            key={"medium"}
                                            value="medium"
                                        >
                                            Medium
                                        </Select.Option>
                                        <Select.Option
                                            key={"hard"}
                                            value="hard"
                                        >
                                            Hard
                                        </Select.Option>
                                        <Select.Option
                                            key={"advanced"}
                                            value="advanced"
                                        >
                                            Advanced
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="collaborators"
                                    label="Project Collaborators"
                                    rules={[
                                        { required: true, message: "required" },
                                    ]}
                                >
                                    <FechUserList />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="mentors"
                                    label="Project Mentors"
                                    rules={[
                                        { required: true, message: "required" },
                                    ]}
                                >
                                    <FechUserList />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Drawer>
        </>
    );
}

export default EditInternshipProject;
