import { Button, message } from "antd";
import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import Compress from "browser-image-compression";
import { uploadFile } from "../../utils/uploadFile";

export default function ImageUploadInput({ onChange, value }) {
    const clickUploadRef = React.useRef(null);
    const handelUploadButtonClick = () => {
        if (clickUploadRef.current) {
            clickUploadRef.current.click();
        }
    };
    const handelFileChange = async (e) => {
        const file = e.target.files[0];
        try {
            const compressedFile = await Compress(file, {
                maxSizeMB: 0.3,
                useWebWorker: true,
            });
            const newfile = new File([compressedFile], file.name, {
                type: file.type,
                lastModified: Date.now(),
            });
            uploadFile(newfile, "internship-projects", () => {})
                .then((res) => {
                    console.log(res);
                    // cb(res.url, { title: file.name });
                    onChange(res.url);
                })
                .catch((err) => {
                    console.log(err);
                    message.error("Something Went Wrong ,Try Again");
                });
        } catch (error) {
            console.log(error);
            message.error("Something Went Wrong ,Try Again");
        }
    };
    return (
        <div>
            {value && <img src={value} alt="img" className="h-20 w-20 object-cover m-2" />}
            <Button onClick={handelUploadButtonClick} icon={<UploadOutlined />}>
                Click to {value ? "Change" : "Upload"} Image
            </Button>
            <input className="hidden" onChange={handelFileChange} type="file" accept="image/*" ref={clickUploadRef} />
        </div>
    );
}
