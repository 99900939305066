import httpService from "./apiService";
const getAllPayments = (pageNo, size, search, search_by) =>
    httpService.get(
        `/v1/payments?${size ? `size=${size}` : ""}${pageNo ? `&pageNo=${pageNo}` : ""}${
            search ? `&search=${search}` : ""
        }${search_by ? `&search_by=${search_by}` : ""}`
    );
const addMissingPayment = (data) => httpService.post("/v1/payments", { ...data });
export default {
    getAllPayments,
    addMissingPayment,
};
