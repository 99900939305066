import httpService from "./apiService";
const couponEndpoint = "/v1/coupons";

const getAllTokens = () => httpService.get(couponEndpoint);
const addnewtoken = (couponInfo) =>
    httpService.post(couponEndpoint, couponInfo);
const deleteCoupon = (tokenId) =>
    httpService.delete(`${couponEndpoint}/${tokenId}`);
const updateCoupon = (tokenId, tokenData) =>
    httpService.put(`${couponEndpoint}/${tokenId}`, tokenData);
export default {
    getAllTokens,
    addnewtoken,
    deleteCoupon,
    updateCoupon,
};
