import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	payments: [],
	paymentCount: 0,
	loading: {
		payments: false,
		submiting: false,
	},
};

const paymentSlice = createSlice({
	name: "payment",
	initialState,
	reducers: {
		getAllPayments() {},
		addMissedpayment() {},
		savePayments(state, action) {
			const { payments, count } = action.payload;
			state.payments = payments;
			state.paymentCount = count;
		},
		setPaymentLoading(state, action) {
			state.loading.payments = action.payload;
		},
		setPaymentSubmiting(state, action) {
			state.loading.submiting = action.payload;
		},
	},
});
export const paymentReducer = paymentSlice.reducer;
export const paymentActions = paymentSlice.actions;
