import httpService from "./apiService";
const addNewProject = (data) => httpService.post("/v1/projects", { ...data });
const getAllProjects = (active) => {
    return httpService.get(`/projects/?active=${active}`);
};

const updateProject = ({ id, projectData }) =>
    httpService.put(`/v1/projects/${id}`, projectData);
const getProjectPaymentsByProjectId = (id) =>
    httpService.get(`/v1/projects/${id}/payments`);
const getProjecEnrolledUsersByProjectId = (id) =>
    httpService.get(`/v1/projects/${id}/enrolled-users`);
const getCheckoutByprojectId = (id) =>
    httpService.get(`/v1/program/${id}/checkouts`);
const updateBasicTimingDetails = ({ id, data }) =>
    httpService.put(`/v1/projects/${id}/basic-timing-details`, data);
export default {
    getAllProjects,
    updateProject,
    addNewProject,
    getProjectPaymentsByProjectId,
    getProjecEnrolledUsersByProjectId,
    getCheckoutByprojectId,
    updateBasicTimingDetails,
};
