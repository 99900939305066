import { message } from "antd";
import React from "react";
import DebounceSelect from "../../../Components/Shared/DebounceSelect";
import userService from "../../../services/userService";
export default function FechUserList({ onChange, value }) {
    async function fetchUsers(email) {
        console.log("fetching user", email);
        try {
            const { data } = await userService.getNewUsers({
                search: email,
                search_by: "email",
            });
            return data.users.map((user) => ({
                label: `${user.name}`,
                value: user._id,
            }));
        } catch (error) {
            message.error(error.message);
            return [];
        }
    }
    return (
        <DebounceSelect
            mode="multiple"
            value={value}
            placeholder="Select users"
            fetchOptions={fetchUsers}
            onChange={onChange}
            style={{
                width: "100%",
            }}
        />
    );
}
