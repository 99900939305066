import { Spin } from "antd";
import React from "react";

function Loader(props) {
	return (
		<div className="example z-50">
			<Spin size="large" />
		</div>
	);
}

export default Loader;
