import { ironManInstance } from "./apiService";

const getAllInstructors = () => {
    return ironManInstance.get("/instructors");
};
const addNewInstructor = (instructorDetails) =>
    ironManInstance.post("/instructors", { ...instructorDetails });
const updateInstructor = (id, instructorDetails) => {
    console.log(id);
    return ironManInstance.patch(`/instructors/${id}`, {
        ...instructorDetails,
    });
};
const deleteInstructor = (id) => ironManInstance.delete(`/instructors/${id}`);

export default {
    getAllInstructors,
    addNewInstructor,
    updateInstructor,
    deleteInstructor,
};
