import React, { useEffect, useState } from 'react';
import { Button, Input, Form, Row, Col, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { authActions } from "../../redux/slices/auth.slice";
import { sendToastNotification } from '../../utils/sendToastNotification';

const Login = () => {
    const dispatch = useDispatch();
    const [loginForm] = Form.useForm();
    const { otpSent, token, currentUser, error, loading } = useSelector((state) => state.auth);

    useEffect(() => {
        if (error) {
        message.error(error);
        sendToastNotification({errorMessage: error});
      }
    }, [error]);
    
    const onSendOTP = (values) => {
        dispatch(authActions.setEmail(values.email));
        dispatch(authActions.sendOtpRequest(values.email))
    };

    const onVerifyOTP = (values) => {
        dispatch(authActions.verifyOtpRequest({ email: values.email, otp: values.otp }));
    };

    if (currentUser && token) {
        return <Redirect to="/newTrainingPrograms" />;
    }

    return (
        <div className="mt-14">
            <Row type="flex" align="middle" className="flex-col">
                <Col className="w-1/4">
                    <h2 className="text-center text-gray-700 text-xl mb-[1.5rem]">
                        Admin Login
                    </h2>
                    <Form
                        form={loginForm}
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={otpSent && !error ? onVerifyOTP : onSendOTP}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your Email!",
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined className="site-form-item-icon rounded-md" />}
                                placeholder="Email"
                            />
                        </Form.Item>

                        {otpSent && !error && (
                            <Form.Item
                                name="otp"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the OTP sent to your email!",
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<MailOutlined className="site-form-item-icon rounded-md" />}
                                    placeholder="OTP"
                                />
                            </Form.Item>
                        )}

                        {!otpSent || error ? (
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="w-full mt-2 rounded-md"
                                    loading={loading}
                                >
                                    Send OTP
                                </Button>
                            </Form.Item>
                        ) : (
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="w-full mt-2 rounded-md"
                                    loading={loading}
                                >
                                    Verify OTP
                                </Button>
                            </Form.Item>
                        )}
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default Login;